import { HStack, Link, Td, Text, Tr } from '@chakra-ui/react';
import { TroveDetails } from '../../utils/inteface';
import { useTroveFor } from '../../store/troves/hooks';
import { useGetAccount, useGetChainId } from '../../hooks/useWagmiHooks';
import { getDisplayBalance } from '../../utils/formatBalance';
import { useContext } from 'react';
import { ThemeContext } from '../../navigation';
import IconLoader from '../../components/Loader/IconLoader';
import useGetCollateralRatio from '../../hooks/helpers/useGetCollateralRatio';
import { getCRColor } from '../../utils/helperFunctions';
import useCore from '../../hooks/useCore';

interface IProps {
  trove: TroveDetails;
}

const PositionRow = (props: IProps) => {
  const { themecolor } = useContext(ThemeContext);
  const core = useCore();
  const chainId = useGetChainId();

  const account = useGetAccount();
  const troveData = useTroveFor(chainId, props.trove.trove, account || '');
  const tokenERC20 = core._tokens[chainId][props.trove.depositToken];

  const collateralRatio = useGetCollateralRatio(
    props.trove.depositToken,
    troveData.collateral,
    troveData.debt,
  );

  const crColor: string = getCRColor(collateralRatio);

  return (
    <Tr>
      <Td>
        <HStack alignItems={'center'}>
          <IconLoader
            iconname={props.trove.depositToken}
            icontype={'tokens'}
            width={8}
          />
          <Text>{props.trove.displayName}</Text>
        </HStack>
      </Td>
      <Td fontFamily={'numbers'}>
        {getDisplayBalance(troveData.collateral, tokenERC20.decimal, 10)}
      </Td>
      <Td fontFamily={'numbers'}>{getDisplayBalance(troveData.debt, 18, 5)}</Td>
      <Td fontFamily={'numbers'} color={`${crColor}`}>
        {collateralRatio.toFixed(2).toString()}%
      </Td>
      <Td fontFamily={'numbers'}>
        <Link href={`/#/position?collateral=${props.trove.trove}`}>
          <Text color={`${themecolor}.400`}>View Position</Text>
        </Link>
      </Td>
    </Tr>
  );
};

export default PositionRow;
