import { Dispatch } from '@reduxjs/toolkit';
import { Protocol } from '../../protocol/Protocol';
import * as Actions from './actions';
import { CallReturnContext } from 'ethereum-multicall';
import { ethers } from 'ethers';
import { addCall } from '../helpers';

export const initUser = (
  core: Protocol,
  dispatch: Dispatch,
  chainId: number,
) => {
  initSP(core, dispatch, chainId);
};

const initSP = (core: Protocol, dispatch: Dispatch, chainId: number) => {
  const depositCall = {
    methodName: 'accountDeposits',
    methodParameters: [core.myAccount],
    callback(e: CallReturnContext) {
      try {
        const abiCoder = ethers.utils.defaultAbiCoder.decode(
          ['uint128', 'uint128'],
          e.returnValues,
        );
        dispatch(
          Actions.updateDepositFor({
            chainId,
            who: core.myAccount,
            amount: abiCoder[0],
          }),
        );
      } catch (e) {
        console.log('initSP depositCall error', e);
      }
    },
  };

  const tokenRewardsCall = {
    methodName: 'claimableReward',
    methodParameters: [core.myAccount],
    callback(e: CallReturnContext) {
      try {
        const abiCoder = ethers.utils.defaultAbiCoder.decode(
          ['uint256'],
          e.returnValues,
        );
        dispatch(
          Actions.updateRewardsFor({
            chainId,
            who: core.myAccount,
            rewards: {
              ZERO: abiCoder[0],
            },
          }),
        );
      } catch (e) {
        console.log('initSP tokenRewardsCall error', e);
      }
    },
  };

  const collRewardsCall = {
    methodName: 'getDepositorCollateralGain',
    methodParameters: [core.myAccount],
    callback(e: CallReturnContext) {
      /*const abiCoder = ethers.utils.defaultAbiCoder.decode(
        ['uint256[]'],
        e.returnValues,
      );*/
      /*dispatch(
        Actions.updateTroveDetailsFor({
          chainId,
          who: core.myAccount,
          trove: trove.trove,
          troveDetails: res,
        })
      );*/
    },
  };

  addCall(
    core,
    chainId,
    core.getStabilityPool(chainId).contract.address,
    core.getStabilityPool(chainId).abi,
    [depositCall, tokenRewardsCall, collRewardsCall],
  );
};
