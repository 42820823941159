import { extendTheme } from '@chakra-ui/react';

export const customTheme = extendTheme({
  fonts: {
    body: 'Open Sans, sans-serif',
    // heading: "Pixelboy, sans-serif",
    heading: 'pixel-boy, sans-serif',
    numbers: 'Oxygen Mono, sans-serif',
  },
  colors: {
    theme: {
      50: '#F8F6FF',
      400: '#7155D3',
      500: '#7A54FF',
      800: '#6574FF',
      900: '#4450BF',
    },
  },
  components: {
    Link: {
      _hover: {
        textDecoration: 'none',
      },
    },
    a: {
      _hover: {
        textDecoration: 'none',
      },
    },
  },
});
