import { createAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

export type BalanceOfType = {
  chainId: number;
  bal: BigNumber;
  who: string;
  token: string;
};
export const updateBalanceOf = createAction<BalanceOfType>(
  'token/updateBalanceOf',
);

export type ApprovalOfType = {
  chainId: number;
  approval: BigNumber;
  from: string;
  to: string;
  token: string;
};
export const updateApprovalOf = createAction<ApprovalOfType>(
  'token/updateApprovalOf',
);

export type PriceFeedType = {
  chainId: number;
  value: number;
  token: string;
};

export const updatePriceFeedOf = createAction<PriceFeedType>(
  'token/updatePriceFeedOf',
);
