import { Table, TableContainer, Tbody, Th, Thead } from '@chakra-ui/react';
import ThemeBoxWrapper from '../../components/ThemeBoxWrapper';
import { useContext } from 'react';
import { ThemeContext } from '../../navigation';
import useCore from '../../hooks/useCore';
import { useGetChainId } from '../../hooks/useWagmiHooks';
import PositionRow from './PositionRow';

const PositionsTable = () => {
  const { themecolor } = useContext(ThemeContext);
  const core = useCore();
  const chainId = useGetChainId();
  const troves = core._config[chainId]['troves'];

  return (
    <ThemeBoxWrapper themecolor={themecolor} p={0}>
      <TableContainer>
        <Table>
          <Thead bgColor={`${themecolor}.400`}>
            <Th fontSize={{ md: 20, sm: 16 }} py={4} color={'white'}>
              Collateral
            </Th>
            <Th fontSize={{ md: 20, sm: 16 }} py={4} color={'white'}>
              Collateral Supplied
            </Th>
            <Th
              fontSize={{ md: 20, sm: 16 }}
              py={4}
              textAlign={'center'}
              color={'white'}
            >
              Debt Borrowed
            </Th>
            <Th
              fontSize={{ md: 20, sm: 16 }}
              py={4}
              textAlign={'center'}
              color={'white'}
            >
              Collateral Ratio
            </Th>
            <Th
              fontSize={{ md: 20, sm: 16 }}
              py={4}
              textAlign={'center'}
              color={'white'}
            >
              Action
            </Th>
          </Thead>
          <Tbody>
            {troves.map((data, index) => {
              return <PositionRow trove={data} key={index} />;
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </ThemeBoxWrapper>
  );
};

export default PositionsTable;
