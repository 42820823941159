import { useCallback } from 'react';
import { useGetChainId } from '../useWagmiHooks';

import useCore from '../useCore';
import { useGetSignedContract } from '../useGetContract';
import { formatToBN, getDisplayBalance } from '../../utils/formatBalance';
import { ZERO_ADDRESS } from '../../utils/constants';
import { TroveDetails } from '../../utils/inteface';
import { BigNumber } from 'ethers';
import { useToast } from '@chakra-ui/react';
import formatErrorMessage from '../../utils/formatErrorMessage';

const useOpenTrove = (
  trove: TroveDetails,
  collateralAmount: BigNumber,
  debtAmount: BigNumber,
  deadline: number,
) => {
  const core = useCore();
  const addToast = useToast();
  const chainId = useGetChainId();
  const delegateBorrowerOperation = core.getDelegateContractOf(
    chainId,
    trove.trove,
  );

  const contract = useGetSignedContract(
    delegateBorrowerOperation.contract.address,
    delegateBorrowerOperation.abi,
  );

  return useCallback(
    async (
      hash: string,
      onInitiating: () => void,
      onSuccess: () => void,
      onFailure: (e: string) => void,
    ): Promise<void> => {
      try {
        onInitiating();

        addToast({
          title: 'Opening Position',
          description: `Minting ${getDisplayBalance(debtAmount, 18, 3)} ONEZ`,
          status: 'warning',
        });

        const response = await contract.openTrove(
          formatToBN(trove.oneTimeFee, 18),
          debtAmount,
          collateralAmount,
          ZERO_ADDRESS,
          ZERO_ADDRESS,
          deadline,
          hash,
          {
            value: trove.trove === 'ETH' ? collateralAmount : BigNumber.from(0),
          },
        );
        const tx = await response.wait();

        if (tx?.status === 1) {
          addToast({
            title: 'Position opened successfully',
            description: `You have minted ${getDisplayBalance(
              debtAmount,
              18,
              3,
            )} ONEZ`,
            status: 'success',
          });
          onSuccess();
        }
        if (tx?.status !== 1) {
          addToast({
            title: 'Error',
            description: `Error Occurred while opening a position`,
            status: 'error',
          });
          onFailure('');
        }
      } catch (e: any) {
        console.log('useV3Deposit error', e);
        onFailure('');
        addToast({
          title: 'Error',
          description: formatErrorMessage(e?.data?.message || e?.message),
          status: 'error',
        });
      }
    },
    [
      addToast,
      collateralAmount,
      contract,
      deadline,
      debtAmount,
      trove.oneTimeFee,
      trove.trove,
    ],
  );
};

export default useOpenTrove;
