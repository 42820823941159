import { useSelector } from 'react-redux';
import { AppState } from '..';
import { BigNumber } from 'ethers';

export function safeGetSPTvlFor(
  spTvlFor: {
    [key: string]: BigNumber;
  },
  chainId: number,
): BigNumber {
  const key = `${chainId}`;
  return spTvlFor[key] ? spTvlFor[key] : BigNumber.from(0);
}

export function useGetSPTvl(chainId: number): BigNumber {
  return useSelector((state: AppState) => {
    return safeGetSPTvlFor(state.app.spTvlFor, chainId);
  });
}
