import { Contract, ethers } from 'ethers';
import { useMemo } from 'react';
import { IAbi } from '../utils/inteface';
import { useGetChainId } from './useWagmiHooks';

import useCore from './useCore';

const useGetContract = (address: string, abi: IAbi): Contract | null => {
  const core = useCore();
  const chainId = useGetChainId();

  return useMemo(() => {
    if (core) {
      return new Contract(address, abi, core.provider[chainId]);
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
};

export const useGetSignedContract = (address: string, abi: IAbi): Contract => {
  return useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(0);
    const contract = new Contract(address, abi, provider);
    return contract.connect(signer);
  }, [abi, address]);
};

export default useGetContract;
