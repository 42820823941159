import { IModalProps, ITxStatus } from '../../../utils/inteface';
import InputContainer from '../../../components/Input/InputContainer';
import {
  formatToBN,
  getDisplayBalance,
  getTokenToUSD,
} from '../../../utils/formatBalance';
import { Box, Button, InputGroup } from '@chakra-ui/react';
import InputField from '../../../components/Input/Input';
import Collateral from '../../../components/Input/Collateral';
import { useContext, useState } from 'react';
import { ThemeContext } from '../../../navigation';
import {
  useTokenBalance,
  useTokenPriceOfByAddress,
} from '../../../store/token/hooks';
import { useGetChainId } from '../../../hooks/useWagmiHooks';
import useCore from '../../../hooks/useCore';
import useRepayDebt from '../../../hooks/callbacks/useRepayDebt';
import { BigNumber } from 'ethers';
import { IManageDebtModalProps } from './ManageDebtModal';
import ModalCRInfo from '../ModalCRInfo';
import useSignedDelegate from '../../../hooks/useSignedDelegate';
import { useSignTypedData } from 'wagmi';
import useGetCollateralRatio from '../../../hooks/helpers/useGetCollateralRatio';
import useApprove, { ApprovalState } from '../../../hooks/callbacks/useApprove';

const RepayDebt = (props: IModalProps & IManageDebtModalProps) => {
  const { themecolor } = useContext(ThemeContext);
  const chainId = useGetChainId();
  const core = useCore();
  const [repayValue, setRepayValue] = useState<string>('');
  const [txStatus, setTxStatus] = useState<ITxStatus>('initial');

  const debtERC20 = core._tokens[chainId]['ONEZ'];
  const debtInUSD = useTokenPriceOfByAddress(chainId, debtERC20.address);
  const debtValueInUSD = getTokenToUSD(Number(repayValue), debtInUSD);
  const debtBalance = useTokenBalance(chainId, debtERC20.address);

  const newCr = useGetCollateralRatio(
    props.trove.depositToken,
    props.troveData.collateral,
    BigNumber.from(props.troveData.debt).sub(formatToBN(repayValue, 18)),
  );
  const newCrColor: string =
    newCr > 150 ? 'green.500' : newCr > 110 ? 'yellow.500' : 'red.500';

  const getSigningData = useSignedDelegate(props.deadline, props.trove);
  const signTypedData = useSignTypedData({
    domain: getSigningData.domain,
    types: getSigningData.DelegateType,
    value: getSigningData.data,
    onSuccess: (data) => {
      handleRepayDebt(data);
    },
  });

  const repayDebtAction = useRepayDebt(
    props.trove,
    formatToBN(repayValue, 18),
    props.deadline,
  );

  const handleRepayDebt = (hash: string) => {
    repayDebtAction(
      hash,
      () => {
        setTxStatus('in-progress');
      },
      () => {
        setTxStatus('successful');
        core.multicall[chainId].tick();
      },
      () => {
        setTxStatus('not-successful');
      },
    );
  };

  //For Approving
  const [approveStatus, approve] = useApprove(
    chainId,
    debtERC20,
    core.getDelegateContractOf(chainId, props.trove.trove).contract.address,
    formatToBN(repayValue, debtERC20.decimal),
  );
  const isApproved = approveStatus === ApprovalState.APPROVED;
  const isApproving = approveStatus === ApprovalState.PENDING;

  return (
    <Box>
      <InputContainer
        label={'Provide Debt you want to repay'}
        theme={themecolor}
        data={`Balance: ${getDisplayBalance(
          debtBalance,
          debtERC20.decimal,
          3,
        )}`}
        footer={`$${debtValueInUSD.toFixed(3)}`}
      >
        <InputGroup>
          <InputField
            value={repayValue}
            onValueChange={setRepayValue}
            placeholder={'0.00'}
          />
          <Collateral selectedToken={'ONEZ'} />
        </InputGroup>
      </InputContainer>
      <Box mt={4}>
        <ModalCRInfo
          cr={props.cr}
          crColor={props.crColor}
          newCr={newCr}
          newCrColor={newCrColor}
        />
        {!isApproved ? (
          <Button
            // colorScheme={(disableNext && value !== '') || trovePresent ? 'red' : `${themecolor}`}
            colorScheme={themecolor}
            w={'100%'}
            size="lg"
            borderRadius={'xl'}
            isLoading={isApproving}
            loadingText={`Approving ${debtERC20.symbol}`}
            onClick={approve}
            isDisabled={isApproving}
          >
            Approve {debtERC20.symbol}
          </Button>
        ) : (
          <Button
            colorScheme={themecolor}
            isDisabled={
              txStatus === 'in-progress' ||
              repayValue === '' ||
              Number(repayValue) === 0
            }
            flex={0.5}
            borderRadius={'xl'}
            loadingText={'Repaying...'}
            isLoading={txStatus === 'in-progress'}
            onClick={() => signTypedData.signTypedData()}
            width={'100%'}
          >
            Repay Debt
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default RepayDebt;
