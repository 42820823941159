import InputContainer from '../../../components/Input/InputContainer';
import {
  formatToBN,
  getDisplayBalance,
  getTokenToUSD,
} from '../../../utils/formatBalance';
import { Box, Button, InputGroup } from '@chakra-ui/react';
import InputField from '../../../components/Input/Input';
import Collateral from '../../../components/Input/Collateral';
import { useContext, useState } from 'react';
import { ThemeContext } from '../../../navigation';
import { useGetChainId } from '../../../hooks/useWagmiHooks';
import useCore from '../../../hooks/useCore';
import { IModalProps, ITxStatus } from '../../../utils/inteface';
import useGenerateDebt from '../../../hooks/callbacks/useGenerateDebt';
import { BigNumber } from 'ethers';
import { IManageDebtModalProps } from './ManageDebtModal';
import ModalCRInfo from '../ModalCRInfo';
import useSignedDelegate from '../../../hooks/useSignedDelegate';
import { useSignTypedData } from 'wagmi';
import {
  useTokenBalance,
  useTokenPriceOfByAddress,
} from '../../../store/token/hooks';
import useGetCollateralRatio from '../../../hooks/helpers/useGetCollateralRatio';

const GenerateDebt = (props: IModalProps & IManageDebtModalProps) => {
  const { themecolor } = useContext(ThemeContext);
  const chainId = useGetChainId();
  const core = useCore();

  const [withdrawValue, setWithdrawValue] = useState<string>('');
  const [txStatus, setTxStatus] = useState<ITxStatus>('initial');

  const debtERC20 = core._tokens[chainId]['ONEZ'];
  const debtInUSD = useTokenPriceOfByAddress(chainId, debtERC20.address);
  const debtValueInUSD = getTokenToUSD(Number(withdrawValue), debtInUSD);

  const newCr = useGetCollateralRatio(
    props.trove.depositToken,
    props.troveData.collateral,
    BigNumber.from(props.troveData.debt).add(formatToBN(withdrawValue, 18)),
  );
  const newCrColor: string =
    newCr > 150 ? 'green.500' : newCr > 110 ? 'yellow.500' : 'red.500';

  const getSigningData = useSignedDelegate(props.deadline, props.trove);

  const signTypedData = useSignTypedData({
    domain: getSigningData.domain,
    types: getSigningData.DelegateType,
    value: getSigningData.data,
    onSuccess: (data) => {
      handleWithdrawDebt(data);
    },
  });

  const withdrawDebtAction = useGenerateDebt(
    props.trove,
    formatToBN(withdrawValue, 18),
    props.deadline,
  );

  const handleWithdrawDebt = (hash: string) => {
    withdrawDebtAction(
      hash,
      () => {
        setTxStatus('in-progress');
      },
      () => {
        setTxStatus('successful');
        core.multicall[chainId].tick();
      },
      () => {
        setTxStatus('not-successful');
      },
    );
  };

  return (
    <Box>
      <InputContainer
        label={'Provide Debt you want to generate'}
        theme={themecolor}
        data={`Your Debt: ${getDisplayBalance(
          props.troveData.debt,
          debtERC20.decimal,
          3,
        )}`}
        footer={`$${debtValueInUSD.toFixed(3)}`}
        p={0}
      >
        <InputGroup>
          <InputField
            value={withdrawValue}
            onValueChange={setWithdrawValue}
            placeholder={'0.00'}
          />
          <Collateral selectedToken={'ONEZ'} />
        </InputGroup>
      </InputContainer>
      <Box mt={4}>
        <ModalCRInfo
          cr={props.cr}
          crColor={props.crColor}
          newCr={newCr}
          newCrColor={newCrColor}
        />
        <Button
          colorScheme={themecolor}
          isDisabled={
            txStatus === 'in-progress' ||
            withdrawValue === '' ||
            Number(withdrawValue) === 0 ||
            newCr < 110
          }
          flex={0.5}
          onClick={() => signTypedData.signTypedData()}
          borderRadius={'xl'}
          isLoading={txStatus === 'in-progress'}
          loadingText={'Generating...'}
          width={'100%'}
        >
          Generate Debt
        </Button>
      </Box>
    </Box>
  );
};

export default GenerateDebt;
