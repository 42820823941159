import { Box, Button, HStack } from '@chakra-ui/react';
import DataInfo from '../../components/DataInfo';
import useOpenTrove from '../../hooks/callbacks/useOpenTrove';
import { formatToBN, getTokenToUSD } from '../../utils/formatBalance';
import { ITxStatus, TroveDetails } from '../../utils/inteface';
import { useGetChainId } from '../../hooks/useWagmiHooks';
import { useMemo, useState } from 'react';
import { useSignTypedData } from 'wagmi';
import useSignedDelegate from '../../hooks/useSignedDelegate';
import { useTokenPriceOfByAddress } from '../../store/token/hooks';
import useCore from '../../hooks/useCore';

interface IProps {
  setStep: (step: 1 | 2 | 3) => void;
  trove: TroveDetails;
  amount: string;
  selectedCollateral: string;
  debtAmount: string;
}

const deadline = Math.floor(Date.now() / 1000 + 86400);

const Confirm = (props: IProps) => {
  const core = useCore();
  const chainId = useGetChainId();
  const [txStatus, setTxStatus] = useState<ITxStatus>('initial');

  const collateralERC20 = core._tokens[chainId][props.selectedCollateral];
  const collateralInUSD = useTokenPriceOfByAddress(
    chainId,
    collateralERC20.address,
  );
  const collateralAmountInUSD = getTokenToUSD(
    Number(props.amount),
    collateralInUSD,
  );

  const debtERC20 = core._tokens[chainId]['ONEZ'];
  const debtInUSD = useTokenPriceOfByAddress(chainId, debtERC20.address);
  const debtAmountInUSD = getTokenToUSD(Number(props.debtAmount), debtInUSD);

  const getSigningData = useSignedDelegate(deadline, props.trove);
  const signTypedData = useSignTypedData({
    domain: getSigningData.domain,
    types: getSigningData.DelegateType,
    value: getSigningData.data,
    onSuccess: (data) => {
      handleOpenTrove(data);
    },
  });

  const openTroveAction = useOpenTrove(
    props.trove,
    formatToBN(props.amount, collateralERC20.decimal),
    formatToBN(props.debtAmount, debtERC20.decimal),
    deadline,
  );
  const handleOpenTrove = (data: string) => {
    openTroveAction(
      data,
      () => {
        setTxStatus('in-progress');
      },
      () => {
        setTxStatus('successful');
        window.open(`/#/position?trove=${props.selectedCollateral}`, '_self');
      },
      () => {
        setTxStatus('not-successful');
      },
    );
  };

  const collateralRatio = useMemo(() => {
    return (collateralAmountInUSD * 100) / debtAmountInUSD;
  }, [collateralAmountInUSD, debtAmountInUSD]);

  const crColor =
    collateralRatio > 150
      ? 'green.500'
      : collateralRatio > 110
        ? 'yellow.500'
        : 'red.500';

  return (
    <Box>
      <DataInfo
        label={'Collateral Supply'}
        value={props.amount}
        symbol={props.selectedCollateral}
        mb={4}
        vprops={{ fontSize: 20 }}
        sProps={{ fontSize: 16 }}
        lProps={{ mb: 1, color: 'purple.500' }}
      />
      <DataInfo
        label={'Total Debt'}
        value={props.debtAmount}
        symbol={'ONEZ'}
        mb={4}
        vprops={{ fontSize: 20 }}
        sProps={{ fontSize: 16 }}
        lProps={{ mb: 1, color: 'purple.500' }}
      />
      <DataInfo
        label={'Collateral Ratio'}
        value={collateralRatio.toFixed(2).toString()}
        symbol={'%'}
        mb={4}
        vprops={{ fontSize: 20, color: crColor }}
        sProps={{ fontSize: 16 }}
        lProps={{ mb: 1, color: 'purple.500' }}
      />
      <HStack mt={8}>
        <Button
          colorScheme="purple"
          variant={'outline'}
          w={'100%'}
          size="lg"
          borderRadius={'xl'}
          onClick={() => props.setStep(2)}
        >
          Go back
        </Button>
        <Button
          colorScheme="purple"
          w={'100%'}
          size="lg"
          borderRadius={'xl'}
          isLoading={txStatus === 'in-progress'}
          loadingText={'Taking Loan'}
          onClick={() => signTypedData.signTypedData()}
        >
          Take Loan
        </Button>
      </HStack>
    </Box>
  );
};

export default Confirm;
