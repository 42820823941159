import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAccount, useGetChainId } from './useWagmiHooks';
import useCore from './useCore';
import { domainType, TroveDetails } from '../utils/inteface';

const useSignedDelegate = (deadline: number, trove: TroveDetails) => {
  const core = useCore();
  const chainId = useGetChainId();
  const account = useGetAccount();
  const borrowerOperation = core.getBorrowerOperationOf(chainId);
  const borrowerOperationAddress = borrowerOperation.contract
    .address as `0x${string}`;
  const delegateBorrowerOperation = core.getDelegateContractOf(
    chainId,
    trove.trove,
  );
  const [nonce, setNonce] = useState<Number>(0);

  const domain: domainType = useMemo(() => {
    return {
      name: 'ONEZ.cash',
      version: '1',
      chainId: chainId,
      verifyingContract: borrowerOperationAddress,
    };
  }, [borrowerOperationAddress, chainId]);

  const DelegateType = {
    Delegate: [
      { name: 'owner', type: 'address' },
      { name: 'delegate', type: 'address' },
      { name: 'value', type: 'bool' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
    ],
  };

  const data = useMemo(() => {
    return {
      owner: account,
      delegate: delegateBorrowerOperation.contract.address,
      value: true,
      deadline: Number(deadline),
      nonce: Number(nonce),
    };
  }, [account, deadline, delegateBorrowerOperation.contract.address, nonce]);

  const getNonce = useCallback(async () => {
    const nonce = await borrowerOperation.contract.nonces(account);
    setNonce(nonce);
  }, [account, borrowerOperation.contract]);

  useEffect(() => {
    getNonce().catch((err) =>
      console.error(`Failed to fetch nonce price: ${err.stack}`),
    );
  }, [getNonce]);

  return {
    data: data,
    domain: domain,
    DelegateType: DelegateType,
  };
};

export default useSignedDelegate;
