import { IModalProps, TroveDetails } from '../../../utils/inteface';
import { ITrove } from '../../../store/troves/actions';
import { useContext } from 'react';
import { ThemeContext } from '../../../navigation';
import CustomModal from '../../../modals';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import RepayDebt from './RepayDebt';
import GenerateDebt from './GenerateDebt';

export interface IManageDebtModalProps {
  trove: TroveDetails;
  troveData: ITrove;
  cr: number;
  crColor: string;
  deadline: number;
}

const ManageDebtModal = (props: IModalProps & IManageDebtModalProps) => {
  const { themecolor } = useContext(ThemeContext);

  return (
    <CustomModal
      isOpen={props.open}
      onClose={props.onClose}
      modalTitle={'Manage Debt'}
      size={'lg'}
      closeButton
    >
      <Box>
        <Tabs isFitted variant="enclosed">
          <TabList mb={4}>
            <Tab _selected={{ bg: themecolor, color: 'white' }}>Repay Debt</Tab>
            <Tab _selected={{ bg: themecolor, color: 'white' }}>
              Generate Debt
            </Tab>
          </TabList>
          <TabPanels p={0}>
            <TabPanel p={0}>
              <RepayDebt {...props} />
            </TabPanel>
            <TabPanel p={0}>
              <GenerateDebt {...props} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </CustomModal>
  );
};

export default ManageDebtModal;
