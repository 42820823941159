import { IColorsType } from '../utils/inteface';
import Container from '../layouts/Container';
import { MainLayout } from '../layouts/MainLayout';
import { ThemeContext } from '../navigation';
import PageHeader from '../layouts/PageHeader';
import PositionsTable from '../modules/positions/PositionsTable';
import { Box } from '@chakra-ui/react';

const PositionPage = () => {
  const color: IColorsType = 'blue';
  return (
    <ThemeContext.Provider value={{ themecolor: color }}>
      <MainLayout>
        <Container maxWidth={940}>
          <Box mt={16}>
            <PageHeader
              title={'POSITIONS'}
              desc={
                'All your positions are visible here' +
                'more points you earn. ZeroLend users earn a 2.5x boost.'
              }
              mb={12}
            />
            <PositionsTable />
          </Box>
        </Container>
      </MainLayout>
    </ThemeContext.Provider>
  );
};

export default PositionPage;
