import config from '../ui-config/markets/marketConfig';
import { TroveDetails } from './inteface';
import { BigNumberish } from '@ethersproject/bignumber';
import { BigNumber, Contract } from 'ethers';
import {
  MAX_TRIALS_AT_ONCE,
  MAX_UINT_256,
  REDEEM_MAX_ITERATIONS,
  ZERO_ADDRESS,
} from './constants';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const truncateMiddle = function (
  fullStr: string = '',
  strLen: number,
  separator?: string,
) {
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || '...';

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 3),
    backChars = Math.floor(charsToShow / 3);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
};

export const troveDetailsOf = (
  trove: string,
  chainId: number,
): TroveDetails | undefined => {
  return config[chainId].troves.filter((troves) => troves.trove === trove)[0];
};

export const getDepositTokensOfAllTroves = (chainId: number): string[] => {
  return config[chainId].troves.map((trove) => {
    return trove.depositToken;
  });
};

type Hint = {
  truncatedARTHamount: BigNumber;
  firstRedemptionHint: string;
  partialRedemptionHintNICR: BigNumber;
};

function* generateTrials(
  totalNumberOfTrials: number,
): IterableIterator<number> {
  //TODO: Fix assert package;
  // assert(Number.isInteger(totalNumberOfTrials) && totalNumberOfTrials > 0);
  while (totalNumberOfTrials) {
    const numberOfTrials = Math.min(totalNumberOfTrials, MAX_TRIALS_AT_ONCE);
    yield numberOfTrials;

    totalNumberOfTrials -= numberOfTrials;
  }
}

const randomInteger = () => Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

export async function findHintsForNominalCollateralRatio(
  nominalCollateralRatio: BigNumber,
  sortedTroves: Contract,
  hintHelpers: Contract,
  troveManager: Contract,
  ownAddress?: string,
): Promise<[string, string]> {
  const numberOfTroves = await troveManager.getTroveOwnersCount();
  if (!numberOfTroves || numberOfTroves.lte(0)) {
    return [ZERO_ADDRESS, ZERO_ADDRESS];
  }

  if (nominalCollateralRatio.eq(BigNumber.from(MAX_UINT_256))) {
    return [ZERO_ADDRESS, await sortedTroves.getFirst()];
  }

  const totalNumberOfTrials = Math.ceil(10 * Math.sqrt(numberOfTroves));
  const [firstTrials, ...restOfTrials]: any =
    generateTrials(totalNumberOfTrials);

  const collectApproxHint = (
    {
      latestRandomSeed,
      results,
    }: {
      latestRandomSeed: BigNumberish;
      results: { diff: BigNumber; hintAddress: string }[];
    },
    numberOfTrials: number,
  ) =>
    hintHelpers
      .getApproxHint(nominalCollateralRatio, numberOfTrials, latestRandomSeed)
      .then(({ latestRandomSeed, ...result }: any) => ({
        latestRandomSeed,
        results: [...results, result],
      }));

  const { results } = await restOfTrials.reduce(
    (p: any, numberOfTrials: number) =>
      p.then((state: any) => collectApproxHint(state, numberOfTrials)),
    collectApproxHint(
      { latestRandomSeed: randomInteger(), results: [] },
      firstTrials,
    ),
  );

  const { hintAddress } = results.reduce((a: any, b: any) =>
    a.diff.lt(b.diff) ? a : b,
  );

  let [prev, next] = await sortedTroves.findInsertPosition(
    nominalCollateralRatio,
    hintAddress,
    hintAddress,
  );

  if (ownAddress) {
    // In the case of reinsertion, the address of the Trove being reinserted is not a usable hint,
    // because it is deleted from the list before the reinsertion.
    // "Jump over" the Trove to get the proper hint.
    if (prev === ownAddress) {
      prev = await sortedTroves.getPrev(prev);
    } else if (next === ownAddress) {
      next = await sortedTroves.getNext(next);
    }
  }

  return prev === ZERO_ADDRESS
    ? [next, next]
    : next === ZERO_ADDRESS
      ? [prev, prev]
      : [prev, next];
}

type RedeemptionHints = [BigNumber, string, string, string, BigNumber];

export async function findRedemptionHints(
  amount: BigNumber,
  sortedTroves: Contract,
  hintHelpers: Contract,
  troveManager: Contract,
  priceFeed: Contract,
  ownAddress?: string,
): Promise<RedeemptionHints> {
  const price = await priceFeed.callStatic.fetchPrice();

  const {
    firstRedemptionHint,
    partialRedemptionHintNICR,
    truncatedARTHamount,
  }: Hint = await hintHelpers.getRedemptionHints(
    amount,
    price,
    REDEEM_MAX_ITERATIONS,
  );

  const [partialRedemptionUpperHint, partialRedemptionLowerHint] =
    partialRedemptionHintNICR.isZero()
      ? [ZERO_ADDRESS, ZERO_ADDRESS]
      : await findHintsForNominalCollateralRatio(
          partialRedemptionHintNICR,
          sortedTroves,
          hintHelpers,
          troveManager,
          ownAddress,
        );

  return [
    truncatedARTHamount,
    firstRedemptionHint,
    partialRedemptionUpperHint,
    partialRedemptionLowerHint,
    partialRedemptionHintNICR,
  ];
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function getCRColor(collateralRatio: number) {
  return collateralRatio > 150
    ? 'green.500'
    : collateralRatio > 110
      ? 'yellow.500'
      : collateralRatio === 0
        ? 'inherit'
        : 'red.500';
}

/*adjustTrove(
  e18, // uint256 _maxFeePercentage,
  0, // uint256 _collDeposit,
  e18.div(10), // uint256 _collWithdrawal,
  0, // uint256 _debtChange,
  false, // bool _isDebtIncrease,
  ZERO_ADDRESS, // address _upperHint,
  ZERO_ADDRESS, // address _lowerHint,
  deadline, // uint256 _deadline,
  hashClose.signature // bytes memory signature
);*/
