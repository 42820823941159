import { Box, Text } from '@chakra-ui/react';
import { MainLayout } from '../layouts/MainLayout';
import Container from '../layouts/Container';
import OverviewContent from '../modules/overview/OverviewContent';
import { IColorsType } from '../utils/inteface';
import { ThemeContext } from '../navigation';
import { troveDetailsOf, useQuery } from '../utils/helperFunctions';
import { useTroveFor } from '../store/troves/hooks';
import { useGetAccount, useGetChainId } from '../hooks/useWagmiHooks';
import { getSupportedTrove } from '../ui-config/markets/marketConfig';
import React from 'react';
import PositionPage from './position.page';

export default function OverviewPage() {
  const color: IColorsType = 'green';
  const chainId = useGetChainId();
  const account = useGetAccount();
  const supportedTrove = getSupportedTrove(chainId);
  const query = useQuery();
  const troveKey = query.get('collateral');
  const trove = troveDetailsOf(troveKey || '', chainId);
  const troveDynamic = useTroveFor(chainId, troveKey || '', account || '');

  if (trove === undefined) return <PositionPage />;

  return (
    <ThemeContext.Provider value={{ themecolor: color }}>
      <MainLayout>
        <Container>
          <Box mt={8}>
            <Box mb={8}>
              <Text
                fontSize={52}
                fontFamily={'heading'}
                align={'center'}
                lineHeight={'90%'}
                color={`${color}.500`}
                mb={4}
              >
                YOUR POSITION
              </Text>
              <Text color={`${color}.500`} align={'center'}>
                Congratulations! Your loan is now paying itself back 🎉
              </Text>
            </Box>
            {supportedTrove.includes(troveKey || '') && (
              <OverviewContent trove={trove} troveData={troveDynamic} />
            )}
            <Box mt={6}></Box>
          </Box>
        </Container>
      </MainLayout>
    </ThemeContext.Provider>
  );
}
