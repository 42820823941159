import { useSelector } from 'react-redux';
import { AppState } from '../index';
import { ISP } from './actions';
import { BigNumber } from 'ethers';

export function safeGetRewardsSPFor(
  spFor: {
    [spKey: string]: {
      [who: string]: ISP;
    };
  },
  chainId: number,
  who: string,
): {
  [key: string]: BigNumber;
} {
  const key = `${chainId}`;
  const spDetails = spFor[key] || {};
  return spDetails[who]
    ? spDetails[who]['rewards']
      ? spDetails[who]['rewards']
      : {}
    : {};
}

export function safeGetSPDepositFor(
  spFor: {
    [spKey: string]: {
      [who: string]: ISP;
    };
  },
  chainId: number,
  who: string,
): BigNumber {
  const key = `${chainId}`;
  const spDetails = spFor[key] || {};
  return spDetails[who]
    ? spDetails[who]['deposit']
      ? BigNumber.from(spDetails[who]['deposit'])
      : BigNumber.from(0)
    : BigNumber.from(0);
}

export function useSPRewardsFor(
  chainId: number,
  who: string,
): {
  [key: string]: BigNumber;
} {
  return useSelector((state: AppState) => {
    return safeGetRewardsSPFor(state.stability.spFor, chainId, who);
  });
}

export function useSPDepositFor(chainId: number, who: string): BigNumber {
  return useSelector((state: AppState) => {
    return safeGetSPDepositFor(state.stability.spFor, chainId, who);
  });
}
