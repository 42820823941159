import { createReducer } from '@reduxjs/toolkit';

import * as Actions from './actions';
import { ITrove } from './actions';

interface State {
  troveFor: {
    [trove: string]: {
      [who: string]: ITrove;
    };
  };
}

export const initialState: State = {
  troveFor: {},
};

export default createReducer(initialState, (builder) =>
  builder.addCase(
    Actions.updateTroveDetailsFor,
    (t, { payload: p }: { payload: Actions.TroveForType }) => {
      t.troveFor = t.troveFor || {};
      const key = `${p.chainId}:${p.trove}`;
      t.troveFor[key] = t.troveFor[key] || {};
      t.troveFor[key][p.who] = p.troveDetails;
    },
  ),
);
