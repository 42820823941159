import Multicall from './abi/Multicall.json';
import ONEZ from './abi/ONEZ.json';
import IERC20 from './abi/IERC20.json';
import PrismaCore from './abi/PrismaCore.json';
import Factory from './abi/Factory.json';
import BorrowerOperations from './abi/BorrowerOperations.json';
import EmptyVault from './abi/EmptyVault.json';
import DebtTokenOnezProxy from './abi/DebtTokenOnezProxy.json';
import GasPool from './abi/GasPool.json';
import LiquidationManager from './abi/LiquidationManager.json';
import TransparentUpgradeableProxy from './abi/TransparentUpgradeableProxy.json';
import SortedTroves from './abi/SortedTroves.json';
import StabilityPool from './abi/StabilityPool.json';
import PriceFeedPyth from './abi/PriceFeedPyth.json';
import MultiCollateralHintHelpers from './abi/MultiCollateralHintHelpers.json';
import MultiTroveGetter from './abi/MultiTroveGetter.json';
import TroveManagerGetters from './abi/TroveManagerGetters.json';
import TroveManager from './abi/TroveManager.json';
import FeeReceiver from './abi/FeeReceiver.json';
import PrismaToken from './abi/PrismaToken.json';
import TokenLocker from './abi/TokenLocker.json';
import IncentiveVoting from './abi/IncentiveVoting.json';
import PrismaVault from './abi/PrismaVault.json';
import WrappedLendingCollateral from './abi/WrappedLendingCollateral.json';
import WETHDelegate from './abi/WETHDelegate.json';
import ERC20Delegate from './abi/ERC20Delegate.json';
import MockV3Aggregator from './abi/MockV3Aggregator.json';
import PriceFeed from './abi/PriceFeed.json';
import { IABIS } from '../../utils/inteface';

const abis: IABIS = {
  Multicall,
  ONEZ,
  IERC20,
  PrismaCore,
  Factory,
  BorrowerOperations,
  DebtTokenOnezProxy,
  TransparentUpgradeableProxy,
  GasPool,
  EmptyVault,
  LiquidationManager,
  SortedTroves,
  StabilityPool,
  PriceFeedPyth,
  MultiCollateralHintHelpers,
  MultiTroveGetter,
  TroveManagerGetters,
  TroveManager,
  FeeReceiver,
  PrismaToken,
  TokenLocker,
  IncentiveVoting,
  PrismaVault,
  WrappedLendingCollateral,
  WETHDelegate,
  ERC20Delegate,
  MockV3Aggregator,
  PriceFeed,
};

export default abis;
