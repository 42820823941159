import InputContainer from '../../../components/Input/InputContainer';
import {
  formatToBN,
  getDisplayBalance,
  getTokenToUSD,
} from '../../../utils/formatBalance';
import { Box, Button, InputGroup } from '@chakra-ui/react';
import InputField from '../../../components/Input/Input';
import Collateral from '../../../components/Input/Collateral';
import { IModalProps, ITxStatus } from '../../../utils/inteface';
import { IManageCollateralModalProps } from './ManageCollateralModal';
import { useContext, useMemo, useState } from 'react';
import useCore from '../../../hooks/useCore';
import { useGetChainId } from '../../../hooks/useWagmiHooks';
import {
  useTokenBalance,
  useTokenPriceOfByAddress,
} from '../../../store/token/hooks';
import useAddCollateral from '../../../hooks/callbacks/useAddCollateral';
import { BigNumber } from 'ethers';
import { ThemeContext } from '../../../navigation';
import ModalCRInfo from '../ModalCRInfo';
import useSignedDelegate from '../../../hooks/useSignedDelegate';
import { useSignTypedData } from 'wagmi';
import useGetCollateralRatio from '../../../hooks/helpers/useGetCollateralRatio';

const AddCollateral = (props: IModalProps & IManageCollateralModalProps) => {
  const { themecolor } = useContext(ThemeContext);
  const core = useCore();
  const chainId = useGetChainId();

  const [value, setValue] = useState<string>('');
  const [txStatus, setTxStatus] = useState<ITxStatus>('initial');

  const collateralERC20 = core._tokens[chainId][props.trove.depositToken];
  const collateralInUSD = useTokenPriceOfByAddress(
    chainId,
    collateralERC20.address,
  );
  const collateralValueInUsd = getTokenToUSD(Number(value), collateralInUSD);
  const collateralBalance = useTokenBalance(chainId, collateralERC20.address);

  const newCr = useGetCollateralRatio(
    props.trove.depositToken,
    BigNumber.from(props.troveData.collateral).add(
      formatToBN(value, collateralERC20.decimal),
    ),
    BigNumber.from(props.troveData.debt),
  );

  const newCrColor: string =
    newCr > 150 ? 'green.500' : newCr > 110 ? 'yellow.500' : 'red.500';

  const getSigningData = useSignedDelegate(props.deadline, props.trove);

  const signTypedData = useSignTypedData({
    domain: getSigningData.domain,
    types: getSigningData.DelegateType,
    value: getSigningData.data,
    onSuccess: (data) => {
      handleAddCollateral(data);
    },
  });

  const addCollateralAction = useAddCollateral(
    props.trove,
    formatToBN(value, collateralERC20.decimal),
    props.deadline,
  );

  const handleAddCollateral = (hash: string) => {
    addCollateralAction(
      hash,
      () => {
        setTxStatus('in-progress');
      },
      () => {
        setTxStatus('successful');
        props.onClose();
      },
      () => {
        setTxStatus('not-successful');
      },
    );
  };

  const disableAction = useMemo(() => {
    return (
      !Number(value) ||
      formatToBN(value, collateralERC20.decimal).gt(collateralBalance) ||
      txStatus === 'in-progress' ||
      txStatus === 'successful'
    );
  }, [value, collateralERC20.decimal, collateralBalance, txStatus]);

  return (
    <Box>
      <Box>
        <InputContainer
          label={'Amount of collateral to add'}
          mb={4}
          data={`Balance: ${getDisplayBalance(
            collateralBalance,
            collateralERC20.decimal,
            3,
          )}`}
          footer={`$${collateralValueInUsd.toFixed(3)}`}
        >
          <InputGroup>
            <InputField
              value={value}
              onValueChange={setValue}
              placeholder={'0.00'}
            />
            <Collateral selectedToken={collateralERC20.symbol} />
          </InputGroup>
        </InputContainer>
      </Box>
      <Box mt={4}>
        <ModalCRInfo
          cr={props.cr}
          crColor={props.crColor}
          newCr={newCr}
          newCrColor={newCrColor}
        />
        <Button
          colorScheme={themecolor}
          flex={0.5}
          borderRadius={'xl'}
          isDisabled={disableAction}
          onClick={() => signTypedData.signTypedData()}
          width={'100%'}
        >
          Add Collateral
        </Button>
      </Box>
    </Box>
  );
};

export default AddCollateral;
