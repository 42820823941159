import { Box, HStack, Text } from '@chakra-ui/react';

interface IProps {
  cr: number;
  crColor: string;
  newCr: number;
  newCrColor: string;
}

const ModalCRInfo = (props: IProps) => {
  return (
    <Box mb={2}>
      <HStack justifyContent={'space-between'} mb={1}>
        <Text fontSize={14} color={'gray.600'}>
          Current Collateral Ratio
        </Text>
        <Text color={props.crColor} fontWeight={'bold'}>
          {props.cr.toFixed(2)} %
        </Text>
      </HStack>
      <HStack justifyContent={'space-between'}>
        <Text fontSize={14} color={'gray.600'}>
          New Collateral Ratio
        </Text>
        <Text color={props.newCrColor} fontWeight={'bold'}>
          {props.newCr.toFixed(2)} %
        </Text>
      </HStack>
    </Box>
  );
};

export default ModalCRInfo;
