import { useSelector } from 'react-redux';
import { AppState } from '../index';
import { ITrove, TroveInitial } from './actions';
import configurations from '../../ui-config/markets/marketConfig';

export function safeGetTroveFor(
  troveFor: {
    [trove: string]: {
      [who: string]: ITrove;
    };
  },
  chainId: number,
  trove: string,
  who: string,
): ITrove {
  const key = `${chainId}:${trove}`;
  const troveDetails = troveFor[key] || {};
  return troveDetails[who] ? troveDetails[who] : TroveInitial;
}

export function safeGetAllTroveFor(
  troveFor: {
    [trove: string]: {
      [who: string]: ITrove;
    };
  },
  chainId: number,
  who: string,
): ITrove[] {
  const resTroves: ITrove[] = [];
  configurations[chainId].troves.forEach((trove) => {
    const key = `${chainId}:${trove.trove}`;
    const troveDetails = troveFor[key] || {};
    resTroves.push(troveDetails[who] ? troveDetails[who] : TroveInitial);
  });
  return resTroves;
}

export function useAllTroveFor(chainId: number, who: string): ITrove[] {
  return useSelector((state: AppState) => {
    return safeGetAllTroveFor(state.trove.troveFor, chainId, who);
  });
}

export function useTroveFor(
  chainId: number,
  trove: string,
  who: string,
): ITrove {
  return useSelector((state: AppState) => {
    return safeGetTroveFor(state.trove.troveFor, chainId, trove, who);
  });
}
