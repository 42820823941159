import { Protocol } from '../protocol/Protocol';
import { CallReturnContext } from 'ethereum-multicall';
import { BigNumber } from 'ethers';

type Params = BigNumber | string | number | string[];

interface ICalls {
  methodName: string;
  methodParameters: Params[];
  callback: (event: CallReturnContext) => void;
}

// helper function to create a multicall hook
export const addCall = (
  core: Protocol,
  chainId: number,
  contractAddress: string,
  abi: unknown[],
  calls: ICalls[],
) => {
  const random = Math.floor(Math.random() * 10000);
  const reference = `${chainId}:${contractAddress}:${random}`;

  const callsWithReference = calls.map((call) => {
    return {
      reference: `${reference}:${call.methodName}:${random}`,
      ...call,
    };
  });

  core.multicall[chainId].on(reference, (event: CallReturnContext[]) => {
    event.forEach((e) => {
      callsWithReference.forEach((call) => {
        if (call.reference === e.reference) call.callback(e);
      });
    });
  });

  core.multicall[chainId].addCall({
    reference,
    contractAddress,
    abi,
    calls: callsWithReference.map((call) => ({
      reference: call.reference,
      methodName: call.methodName,
      methodParameters: call.methodParameters,
    })),
  });
};
