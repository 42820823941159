import { Box } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import SiteMenu from '../modals/SiteMenu';
import { useContext, useState } from 'react';
import { ThemeContext } from '../navigation';

export default function HamburgerMenu() {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { themecolor } = useContext(ThemeContext);

  return (
    <Box
      position={'fixed'}
      bottom={2}
      right={2}
      cursor={'pointer'}
      p={2}
      width={'40px'}
      height={'40px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      background={`${themecolor}.500`}
      borderRadius={'10%'}
      onClick={() => setOpenMenu(true)}
    >
      <SiteMenu onClose={() => setOpenMenu(false)} open={openMenu} />
      <HamburgerIcon boxSize={4} color={'white'} />
    </Box>
  );
}
