import { createAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

export type ITrove = {
  trove: string;
  collateral: BigNumber;
  debt: BigNumber;
};

export type TroveForType = {
  chainId: number;
  who: string;
  trove: string;
  troveDetails: ITrove;
};

export const TroveInitial: ITrove = {
  trove: '',
  collateral: BigNumber.from(0),
  debt: BigNumber.from(0),
};

export const updateTroveDetailsFor = createAction<TroveForType>(
  'user/updateBalanceForPosition',
);
