import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import config from '../ui-config/markets/marketConfig';
import { Protocol } from '../protocol/Protocol';
import { useGetAccount, useGetChainId } from '../hooks/useWagmiHooks';

export interface ProtocolContext {
  core: Protocol;
}

// @ts-ignore
export const Context = createContext<ProtocolContext>({ core: null });

interface IProps {
  children: React.ReactNode;
}

export const ProtocolProvider = (props: IProps) => {
  const { children } = props;
  const account = useGetAccount();
  const chainId = useGetChainId();

  const [core, setCore] = useState<Protocol>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!core) {
      const newCore = new Protocol(config, chainId);
      setCore(newCore);
    }

    return () => {
      if (core) core.cleanup(config);
    };
  }, [dispatch, core, chainId]);

  useEffect(() => {
    if (account && core) core.unlockWallet(window.ethereum, account, dispatch);
  }, [account, core, dispatch, chainId]);

  if (core)
    return <Context.Provider value={{ core }}>{children}</Context.Provider>;

  return <div />;
};
