import {
  Box,
  Button,
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import DataInfo from '../../components/DataInfo';
import { useMemo, useState } from 'react';
import { TroveDetails } from '../../utils/inteface';
import { getTokenToUSD } from '../../utils/formatBalance';
import { useTokenPriceOfByAddress } from '../../store/token/hooks';
import useCore from '../../hooks/useCore';
import { useGetChainId } from '../../hooks/useWagmiHooks';

interface IProps {
  amount: string;
  selectedCollateral: string;
  debtAmount: string;
  setDebtAmount: (value: string) => void;
  trove: TroveDetails;
  setStep: (step: 1 | 2 | 3) => void;
}

const Borrow = (props: IProps) => {
  const { amount, selectedCollateral, setDebtAmount, debtAmount } = props;
  const core = useCore();
  const chainId = useGetChainId();

  const [sliderValue, setSliderValue] = useState<number>(60);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const collateralERC20 = core._tokens[chainId][selectedCollateral];
  const collateralInUSD = useTokenPriceOfByAddress(
    chainId,
    collateralERC20.address,
  );
  const collateralAmountInUSD = getTokenToUSD(Number(amount), collateralInUSD);

  const debtERC20 = core._tokens[chainId]['ONEZ'];
  const debtInUSD = useTokenPriceOfByAddress(chainId, debtERC20.address);
  const debtAmountInUSD = getTokenToUSD(Number(debtAmount), debtInUSD);

  const numberOfOnez = useMemo(() => {
    const oneCollateralInOnez = collateralInUSD / debtInUSD;

    const debtToMint =
      oneCollateralInOnez * Number(amount) * (sliderValue / 100);
    const debtAmount =
      debtToMint +
      props.trove.liquidationReserve + // adding liquidation reserve
      debtToMint * (5 / 100); // adding one time fee

    setDebtAmount(debtAmount.toString());
    return debtAmount;
  }, [
    amount,
    collateralInUSD,
    debtInUSD,
    props.trove.liquidationReserve,
    setDebtAmount,
    sliderValue,
  ]);

  const collateralRatio = useMemo(() => {
    return (collateralAmountInUSD * 100) / debtAmountInUSD;
  }, [collateralAmountInUSD, debtAmountInUSD]);

  const crColor =
    collateralRatio > 150
      ? 'green.500'
      : collateralRatio > 110
        ? 'yellow.500'
        : 'red.500';

  const error = useMemo(() => {
    if (collateralRatio < 110) {
      return {
        color: 'red',
        msg: 'Collateral ratio should be more than 110%',
      };
    } else if (Number(props.debtAmount) < Number(props.trove.minDebtAmount)) {
      return {
        color: 'red',
        msg: `Minimum debt amount has to be ${props.trove.minDebtAmount} ONEZ`,
      };
    } else if (collateralRatio < 150) {
      return {
        color: 'yellow',
        msg: 'Its recommended to keep the collateral ratio more than 150%',
      };
    } else {
      return {
        color: '',
        msg: ``,
      };
    }
  }, [collateralRatio, props.debtAmount, props.trove.minDebtAmount]);

  return (
    <Box>
      <DataInfo
        label={'Your collateral that will earn a yield'}
        value={amount}
        symbol={selectedCollateral}
        mb={4}
        vprops={{ fontSize: 20 }}
        sProps={{ fontSize: 16 }}
        lProps={{ mb: 1, color: 'purple.500' }}
      />
      <Box mb={3}>
        <Text color={'purple.500'}>How much ONEZ would you like to mint?</Text>
        <Box py={2} pr={4}>
          <Slider
            id="slider"
            value={sliderValue}
            min={0}
            max={80}
            colorScheme="purple"
            onChange={(v) => (v <= 80 ? setSliderValue(v) : {})}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {/*<SliderMark value={10} mt='1' ml='-2.5' fontSize='sm'>
              10%
            </SliderMark>
            <SliderMark value={25} mt='1' ml='-2.5' fontSize='sm'>
              25%
            </SliderMark>
            <SliderMark value={50} mt='1' ml='-2.5' fontSize='sm'>
              50%
            </SliderMark>
            <SliderMark value={75} mt='1' ml='-2.5' fontSize='sm'>
              75%
            </SliderMark>*/}
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <Tooltip
              hasArrow
              bg="purple.500"
              color="white"
              placement="top"
              isOpen={showTooltip}
              label={`${sliderValue}%`}
            >
              <SliderThumb />
            </Tooltip>
          </Slider>
        </Box>
      </Box>
      <DataInfo
        label={'You will mint'}
        value={numberOfOnez.toFixed(3)}
        symbol={'ONEZ'}
        mb={4}
        vprops={{ fontSize: 20 }}
        sProps={{ fontSize: 16 }}
        lProps={{ mb: 1, color: 'purple.500' }}
      />
      <DataInfo
        label={'Collateral Ratio'}
        value={collateralRatio.toFixed(2).toString()}
        symbol={'%'}
        mb={4}
        vprops={{ fontSize: 20, color: crColor }}
        sProps={{ fontSize: 16 }}
        lProps={{ mb: 1, color: 'purple.500' }}
      />
      <Box mt={8}>
        <Text color={`${error.color}.500`} mb={1} textAlign={'center'}>
          {error.msg}
        </Text>
        <HStack>
          <Button
            colorScheme="purple"
            variant={'outline'}
            w={'100%'}
            size="lg"
            borderRadius={'xl'}
            isLoading={false}
            loadingText={'Approving ETH'}
            onClick={() => props.setStep(1)}
          >
            Go back
          </Button>
          <Button
            colorScheme="purple"
            w={'100%'}
            size="lg"
            borderRadius={'xl'}
            onClick={() => props.setStep(3)}
            isDisabled={error.color === 'red'}
          >
            Next
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

export default Borrow;
