import { Box, BoxProps, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { ThemeContext } from '../navigation';

interface IProps {
  title: string;
  desc: string;
}

const PageHeader = (props: IProps & BoxProps) => {
  const { themecolor } = useContext(ThemeContext);

  return (
    <Box {...props}>
      <Text
        fontSize={{ base: 32, md: 52 }}
        fontFamily={'heading'}
        align={'center'}
        lineHeight={'90%'}
        color={`${themecolor}.500`}
        mb={{ base: 0, md: 4 }}
      >
        {props.title}
      </Text>
      <Text color={`${themecolor}.500`} align={'center'}>
        {props.desc}
      </Text>
    </Box>
  );
};

export default PageHeader;
