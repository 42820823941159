import { Box, Button, HStack, Text } from '@chakra-ui/react';
import ThemeBoxWrapper from '../../components/ThemeBoxWrapper';
import DataInfo from '../../components/DataInfo';
import { useContext, useMemo, useState } from 'react';
import { ThemeContext } from '../../navigation';
import { ITxStatus, TroveDetails } from '../../utils/inteface';
import { ITrove } from '../../store/troves/actions';
import {
  formatToBN,
  getDisplayBalance,
  getTokenBNToUSD,
  getTokenToUSD,
} from '../../utils/formatBalance';
import { useGetChainId } from '../../hooks/useWagmiHooks';
import useCloseTrove from '../../hooks/callbacks/useCloseTrove';
import {
  useTokenBalance,
  useTokenPriceOfByAddress,
} from '../../store/token/hooks';
import useCore from '../../hooks/useCore';
import { BigNumber } from 'ethers';
import useApprove, {
  APPROVE_AMOUNT,
  ApprovalState,
} from '../../hooks/callbacks/useApprove';
import ManageDebtModal from './ManageDebt/ManageDebtModal';
import ManageCollateralModal from './ManageCollateral/ManageCollateralModal';
import useSignedDelegate from '../../hooks/useSignedDelegate';
import { useSignTypedData } from 'wagmi';
import useGetCollateralRatio from '../../hooks/helpers/useGetCollateralRatio';
import { SettingsIcon } from '@chakra-ui/icons';

interface IProps {
  trove: TroveDetails;
  troveData: ITrove;
}

const deadline = Math.floor(Date.now() / 1000 + 86400);

const OverviewContent = (props: IProps) => {
  const { themecolor } = useContext(ThemeContext);

  const core = useCore();
  const chainId = useGetChainId();
  const [debtModal, setDebtModal] = useState<boolean>(false);
  const [collModal, setCollModal] = useState<boolean>(false);
  const [txStatus, setTxStatus] = useState<ITxStatus>('initial');
  const getSigningData = useSignedDelegate(deadline, props.trove);

  const debtERC20 = core._tokens[chainId]['ONEZ'];
  const debtInUSD = useTokenPriceOfByAddress(chainId, debtERC20.address);
  const debtAmountInUSD = getTokenBNToUSD(
    props.troveData.debt,
    debtInUSD,
    debtERC20.decimal,
  );
  const onezBalance = useTokenBalance(chainId, debtERC20.address);

  const collateralERC20 = core._tokens[chainId][props.trove.depositToken];
  const collateralInUSD = useTokenPriceOfByAddress(
    chainId,
    collateralERC20.address,
  );
  const collateralAmountInUSD = getTokenBNToUSD(
    props.troveData.collateral,
    collateralInUSD,
    collateralERC20.decimal,
  );

  const collateralTo = useMemo(() => {
    const collateral = Number(
      getDisplayBalance(
        props.troveData.collateral,
        collateralERC20.decimal,
        10,
      ),
    );
    const ratePerHour = 2 / 365 / 24;
    const collateralPerHour = collateral * (ratePerHour / 100);
    return collateralPerHour * 4 + collateral;
  }, [collateralERC20.decimal, props.troveData.collateral]);

  const collateralRatio = useGetCollateralRatio(
    props.trove.depositToken,
    props.troveData.collateral,
    props.troveData.debt,
  );

  const collateralRatioTo = useGetCollateralRatio(
    props.trove.depositToken,
    formatToBN(collateralTo, 18),
    props.troveData.debt,
  );

  const crColor: string =
    collateralRatio > 150
      ? 'green.500'
      : collateralRatio > 110
        ? 'yellow.500'
        : 'red.500';

  const signTypedData = useSignTypedData({
    domain: getSigningData.domain,
    types: getSigningData.DelegateType,
    value: getSigningData.data,
    onSuccess: (data) => {
      handleCloseTrove(data);
    },
  });

  const closeTroveAction = useCloseTrove(props.trove, deadline);

  const handleCloseTrove = (hash: string) => {
    closeTroveAction(
      hash,
      () => {
        setTxStatus('in-progress');
      },
      () => {
        setTxStatus('successful');
        window.open(`/#/`, '_self');
      },
      () => {
        setTxStatus('not-successful');
      },
    );
  };

  // For Approving
  const [approveStatus, approve] = useApprove(
    chainId,
    debtERC20,
    core.getDelegateContractOf(chainId, props.trove.trove).contract.address,
    APPROVE_AMOUNT, // props.troveData.debt,
  );
  const isApproved = approveStatus === ApprovalState.APPROVED;
  const isApproving = approveStatus === ApprovalState.PENDING;

  const isOnezSufficient = BigNumber.from(onezBalance).gte(props.troveData.debt)
    ? 1
    : BigNumber.from(props.troveData.debt).sub(BigNumber.from(onezBalance));

  return (
    <ThemeBoxWrapper themecolor={themecolor}>
      <ManageDebtModal
        open={debtModal}
        onClose={() => setDebtModal(false)}
        trove={props.trove}
        troveData={props.troveData}
        cr={collateralRatio}
        crColor={crColor}
        deadline={deadline}
      />
      <ManageCollateralModal
        open={collModal}
        onClose={() => setCollModal(false)}
        trove={props.trove}
        troveData={props.troveData}
        cr={collateralRatio}
        crColor={crColor}
        deadline={deadline}
      />
      <Box>
        <DataInfo
          label={'Your Collateral'}
          labelAction={
            <Text
              fontSize={12}
              color={`${themecolor}.700`}
              fontWeight={'bold'}
              cursor={'pointer'}
              onClick={() => setCollModal(true)}
            >
              Manage Collateral <SettingsIcon />
            </Text>
          }
          value={getDisplayBalance(
            props.troveData.collateral,
            collateralERC20.decimal,
            10,
          )}
          valueInUsd={`$${collateralAmountInUSD.toFixed(2).toString()}`}
          symbol={'ETH'}
          mb={4}
          /*animate={{
          from: Number(getDisplayBalance(props.troveData.collateral, collateralERC20.decimal, 10)),
          to: collateralTo,
          hrs: 4,
          decimals: 8,
        }}*/
        />
        <DataInfo
          label={'Your Debt'}
          labelAction={
            <Text
              fontSize={12}
              color={`${themecolor}.700`}
              fontWeight={'bold'}
              cursor={'pointer'}
              onClick={() => setDebtModal(true)}
            >
              Manage Debt <SettingsIcon />
            </Text>
          }
          value={getDisplayBalance(props.troveData.debt, 18, 2)}
          valueInUsd={`$${debtAmountInUSD.toFixed(2).toString()}`}
          symbol={'ONEZ'}
          mb={4}
        />
        <DataInfo
          label={'Collateral Ratio'}
          value={Number(collateralRatio).toFixed(2).toString()}
          symbol={'%'}
          /* animate={{
             from: collateralRatio,
             to: collateralRatioTo,
             hrs: 4,
             decimals: 6,
           }}*/
          vprops={{ color: crColor }}
        />
      </Box>
      <Box mt={8}>
        {/*<HStack mb={4}>
          <Button
            colorScheme={themecolor}
            w={'100%'}
            size="lg"
            isLoading={false}
            variant={'outline'}
            loadingText={'Approving ETH'}
            onClick={() => setCollModal(true)}
            borderRadius={'xl'}
          >
            Manage Collateral
          </Button>
          <Button
            colorScheme={themecolor}
            variant={'outline'}
            w={'100%'}
            size="lg"
            onClick={() => setDebtModal(true)}
            borderRadius={'xl'}
          >
            Manage Debt
          </Button>
        </HStack>*/}
        {isOnezSufficient !== 1 && (
          <Text textAlign={'center'} mb={2}>
            Your cannot close your position cause your ONEZ balance is only{' '}
            {getDisplayBalance(onezBalance, 18, 4)}ONEZ you need{' '}
            {getDisplayBalance(isOnezSufficient, 18, 3)}ONEZ more
          </Text>
        )}
        <HStack>
          {!isApproved ? (
            <Button
              colorScheme={themecolor}
              w={'100%'}
              size="lg"
              borderRadius={'xl'}
              isLoading={isApproving}
              loadingText={`Approving ONEZ`}
              onClick={approve}
              isDisabled={isOnezSufficient !== 1}
            >
              Approve ONEZ
            </Button>
          ) : (
            <Button
              colorScheme={themecolor}
              w={'100%'}
              size="lg"
              isLoading={txStatus === 'in-progress'}
              loadingText={'Closing Loan'}
              isDisabled={isOnezSufficient !== 1}
              onClick={() => signTypedData.signTypedData()}
              borderRadius={'xl'}
            >
              Close Position
            </Button>
          )}
        </HStack>
      </Box>
    </ThemeBoxWrapper>
  );
};

export default OverviewContent;
