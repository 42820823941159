import { Dispatch } from '@reduxjs/toolkit';
import { Protocol } from '../../protocol/Protocol';
import * as Actions from './actions';
import { ITrove } from './actions';
import { CallReturnContext } from 'ethereum-multicall';
import { ethers } from 'ethers';
import { addCall } from '../helpers';

export const initUser = (
  core: Protocol,
  dispatch: Dispatch,
  chainId: number,
) => {
  initTroves(core, dispatch, chainId);
};

const initTroves = (core: Protocol, dispatch: Dispatch, chainId: number) => {
  core._config[chainId].troves.map((trove) => {
    const troveDetailsCall = {
      methodName: 'Troves',
      methodParameters: [core.myAccount],
      callback(e: CallReturnContext) {
        try {
          const abiCoder = ethers.utils.defaultAbiCoder.decode(
            ['uint256', 'uint256', 'uint256', 'uint8', 'uint128', 'address'],
            e.returnValues,
          );
          const res: ITrove = {
            trove: trove.trove,
            collateral: abiCoder[1],
            debt: abiCoder[0],
          };
          dispatch(
            Actions.updateTroveDetailsFor({
              chainId,
              who: core.myAccount,
              trove: trove.trove,
              troveDetails: res,
            }),
          );
        } catch (e) {
          console.log('initTroves error', e);
        }
      },
    };

    addCall(
      core,
      chainId,
      trove.troveAddress,
      core.getTroveManager(chainId).abi,
      [troveDetailsCall],
    );
  });
};
