import { Contract, ethers } from 'ethers';
import { ZERO_ADDRESS } from '../utils/constants';
import { Configuration } from '../utils/inteface';

import * as tokenState from '../store/token/controller';
import * as appState from '../store/app/controller';
import * as troveState from '../store/troves/controller';
import * as stabilityState from '../store/stability/controller';

import ABIS from './deployments/abi';
import ERC20 from './ERC20';
import Multicall from './Mulitcall';
import { getSupportedTokens } from '../ui-config/markets/marketConfig';

export class Protocol {
  myAccount: string = '';
  _config: { [chainId: number]: Configuration };
  _contracts: { [chainId: number]: { [name: string]: Contract } };
  _tokens: { [chainId: number]: { [name: string]: ERC20 } };
  _activeNetwork: number;

  signer: { [chainId: number]: ethers.Signer } = {};
  provider: { [chainId: number]: ethers.providers.JsonRpcProvider };
  // @ts-ignore
  _signer: ethers.Signer;
  // @ts-ignore
  _provider: ethers.providers.JsonRpcProvider;

  multicall: { [chainId: number]: Multicall } = {};

  constructor(cfg: { [chainId: number]: Configuration }, chainId: number) {
    this._activeNetwork = chainId;
    this._contracts = {};
    this._tokens = {};
    this._tokens = {};
    this.provider = {};

    try {
      for (const [chainIdString, config] of Object.entries(cfg)) {
        const chainId = Number(chainIdString);
        const { deployments } = config;
        this.provider[chainId] = new ethers.providers.JsonRpcProvider(
          config.defaultProvider,
        );
        this._provider = new ethers.providers.JsonRpcProvider(
          config.defaultProvider,
        );
        const networkConfig: { [name: string]: Contract } = {};
        const tokens: { [name: string]: ERC20 } = {};

        for (const [name, deployment] of Object.entries(deployments)) {
          if (!deployment.abi) continue;

          // to push all erc20 tokens in tokens array

          if (getSupportedTokens(chainId).includes(name)) {
            tokens[name] = new ERC20(
              deployments[name].address,
              this._provider,
              name,
              cfg[chainId].decimalOverrides[name] || 18,
            );
          } else {
            if (ABIS[deployment.abi]) {
              // to push all others as contracts
              networkConfig[name] = new Contract(
                deployment.address,
                ABIS[deployment.abi],
                this._provider,
              );
            } else {
              console.error('ABI not found for', name);
            }
          }
        }

        // add ETH as a token
        tokens['ETH'] = new ERC20(ZERO_ADDRESS, this._provider, 'ETH', 18);

        this._contracts[chainId] = networkConfig;
        this._tokens[chainId] = tokens;
        this.multicall[chainId] = new Multicall(this._provider, chainId);
      }
    } catch (e) {
      console.log('Error in contracts mapping', e);
    }
    this._config = cfg;
  }

  cleanup(cfg: { [chainId: number]: Configuration }) {
    for (const [chainIdString] of Object.entries(cfg)) {
      clearInterval(this.multicall[Number(chainIdString)].intervalId);
    }
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   * @param dispatch
   */
  unlockWallet(provider: any, account: string, dispatch: any) {
    this._signer = this._provider.getSigner(0);
    this.myAccount = account;

    for (const [chainIdStr] of Object.entries(this._config)) {
      const chainId = Number(chainIdStr);
      this.multicall[chainId].resetCalls();
      appState.initApp(this, dispatch, chainId);
      tokenState.initUser(this, dispatch, chainId);
      troveState.initUser(this, dispatch, chainId);
      stabilityState.initUser(this, dispatch, chainId);
      this.multicall[chainId].tick();
    }
  }

  getERC20Token(chainId: number, symbol: string): { token: ERC20; abi: any } {
    return {
      token: this._tokens[chainId][symbol],
      abi: ABIS.IERC20,
    };
  }

  getBorrowerOperationOf(chainId: number): { contract: Contract; abi: any } {
    return {
      contract: this._contracts[chainId][`BorrowerOperations`],
      abi: ABIS.BorrowerOperations,
    };
  }

  getPriceFeedContract(chainId: number): { contract: Contract; abi: any } {
    return {
      contract: this._contracts[chainId][`PriceFeedPyth`],
      abi: ABIS.PriceFeedPyth,
    };
  }

  getDelegateContractOf(
    chainId: number,
    trove: 'ETH' | 'USDC' | 'MANTA',
  ): { contract: Contract; abi: any } {
    /*return {
      contract: new Contract(this._config[chainId]['addresss'][`${trove}_DELEGATE`], this._provider[chainId], ABIS.ERC20Delegate),

    }*/
    if (trove === 'ETH') {
      return {
        contract: this._contracts[chainId][`W${trove}DelegateW${trove}`],
        abi: ABIS.WETHDelegate,
      };
    } else {
      return {
        contract: this._contracts[chainId][`ERC20Delegate${trove}`],
        abi: ABIS.ERC20Delegate,
      };
    }
  }

  getStabilityPool(chainId: number): { contract: Contract; abi: any } {
    return {
      contract: this._contracts[chainId][`StabilityPool`],
      abi: ABIS.StabilityPool,
    };
  }

  getTroveManager(chainId: number): { contract: Contract; abi: any } {
    return {
      contract: this._contracts[chainId][`TroveManager`],
      abi: ABIS.TroveManager,
    };
  }

  getDebtTokenProxy(chainId: number): { contract: Contract; abi: any } {
    return {
      contract: this._contracts[chainId][`DebtTokenOnezProxy`],
      abi: ABIS.DebtTokenOnezProxy,
    };
  }
}
