import { IModalProps, TroveDetails } from '../../../utils/inteface';
import { ITrove } from '../../../store/troves/actions';
import { useContext } from 'react';
import { ThemeContext } from '../../../navigation';
import CustomModal from '../../../modals';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import AddCollateral from './AddCollateral';
import WithdrawCollateral from './WithdrawCollateral';

export interface IManageCollateralModalProps {
  trove: TroveDetails;
  troveData: ITrove;
  cr: number;
  crColor: string;
  deadline: number;
}

const ManageCollateralModal = (
  props: IModalProps & IManageCollateralModalProps,
) => {
  const { themecolor } = useContext(ThemeContext);

  return (
    <CustomModal
      isOpen={props.open}
      onClose={props.onClose}
      modalTitle={'Manage Collateral'}
      size={'lg'}
      closeButton
    >
      <Box>
        <Tabs isFitted variant="enclosed">
          <TabList mb={4}>
            <Tab _selected={{ bg: themecolor, color: 'white' }}>
              Add Collateral
            </Tab>
            <Tab _selected={{ bg: themecolor, color: 'white' }}>
              Remove Collateral
            </Tab>
          </TabList>
          <TabPanels p={0}>
            <TabPanel p={0}>
              <AddCollateral {...props} />
            </TabPanel>
            <TabPanel p={0}>
              <WithdrawCollateral {...props} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </CustomModal>
  );
};

export default ManageCollateralModal;
