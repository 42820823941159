import { HStack, Text } from '@chakra-ui/react';
import ThemeBoxWrapper from '../../components/ThemeBoxWrapper';
import TakeCollateral from './TakeCollateral';
import { useContext, useEffect, useState } from 'react';
import Borrow from './Borrow';
import { AnimatePresence, motion } from 'framer-motion';
import Confirm from './Confirm';
import { ThemeContext } from '../../navigation';
import { useGetAccount, useGetChainId } from '../../hooks/useWagmiHooks';
import { useTroveFor } from '../../store/troves/hooks';
import { BigNumber } from 'ethers';
import { TroveDetails } from '../../utils/inteface';
import { useNavigate } from 'react-router-dom';

interface IProps {
  trove: TroveDetails;
}

const MintContent = (props: IProps) => {
  const { trove } = props;
  const navigate = useNavigate();
  const { themecolor } = useContext(ThemeContext);
  const chainId = useGetChainId();
  const account = useGetAccount();
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [value, setValue] = useState<string>('0');
  const [selectedCollateral, setSelectedCollateral] = useState<string>(
    trove.depositToken,
  );
  const [debtAmount, setDebtAmount] = useState<string>('0');
  const troveDynamic = useTroveFor(chainId, selectedCollateral, account || '');
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    setSelectedCollateral(trove.depositToken);
  }, [trove.depositToken]);

  useEffect(() => {
    if (troveDynamic.trove === '' && account && !fetching) {
      setFetching(true);
    } else if (troveDynamic.trove !== '') {
      setFetching(false);
    }
  }, [account, fetching, troveDynamic.trove]);

  return (
    <ThemeBoxWrapper themecolor={themecolor} overflow={'hidden'}>
      {/*<SettingUpModal open={fetching} onClose={() => setFetching(false)}/>*/}
      <HStack mb={4}>
        <Text
          fontWeight={step >= 1 ? 'bold' : ''}
          fontSize={14}
          color={`${themecolor}.400`}
        >
          1. Deposit
        </Text>
        <Text
          fontWeight={step >= 2 ? 'bold' : ''}
          fontSize={14}
          color={`${themecolor}.400`}
        >
          /
        </Text>
        <Text
          fontWeight={step >= 2 ? 'bold' : ''}
          fontSize={14}
          color={`${themecolor}.400`}
          opacity={step >= 2 ? 1 : 0.8}
        >
          2. Borrow
        </Text>
        <Text
          fontWeight={step >= 3 ? 'bold' : ''}
          fontSize={14}
          color={`${themecolor}.400`}
        >
          /
        </Text>
        <Text
          fontWeight={step >= 3 ? 'bold' : ''}
          fontSize={14}
          color={`${themecolor}.400`}
          opacity={step >= 3 ? 1 : 0.8}
        >
          3. Magic 🪄
        </Text>
      </HStack>
      {step === 1 && (
        <AnimatePresence mode={'wait'}>
          <motion.div
            key={step === 1 ? 'take-collateral' : 'empty'}
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <TakeCollateral
              setStep={setStep}
              value={value}
              setValue={setValue}
              selectedCollateral={selectedCollateral}
              setSelectedCollateral={(token) =>
                navigate('/mint?collateral=' + token)
              }
              debtAmount={debtAmount}
              setDebtAmount={setDebtAmount}
              trove={trove}
              trovePresent={BigNumber.from(troveDynamic.collateral).gt(0)}
            />
          </motion.div>
        </AnimatePresence>
      )}
      {step === 2 && (
        <AnimatePresence mode={'wait'}>
          <motion.div
            key={step === 2 ? 'borrow' : 'empty'}
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Borrow
              setStep={setStep}
              amount={value}
              selectedCollateral={selectedCollateral}
              trove={trove}
              debtAmount={debtAmount}
              setDebtAmount={setDebtAmount}
            />
          </motion.div>
        </AnimatePresence>
      )}
      {step === 3 && (
        <AnimatePresence mode={'wait'}>
          <motion.div
            key={step === 3 ? 'confirm' : 'empty'}
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Confirm
              setStep={setStep}
              trove={trove}
              selectedCollateral={selectedCollateral}
              amount={value}
              debtAmount={debtAmount}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </ThemeBoxWrapper>
  );
};

export default MintContent;
