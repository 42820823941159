import { HStack, Link, Text } from '@chakra-ui/react';

const AppFooter = () => {
  return (
    <HStack
      width={'100%'}
      alignItems={'center'}
      opacity={0.5}
      fontSize={'small'}
      justifyContent={'center'}
      pb={4}
    >
      <Text>
        Made with ❤️ by <Link href={'https://zerolend.xyz'}>ZeroLend</Link>
      </Text>
    </HStack>
  );
};

export default AppFooter;
