import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useMediaQuery,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface IProps {
  modalFooter?: ReactNode;
  modalTitle?: ReactNode;
  closeButton?: boolean;
  children: ReactNode;
}

const CustomModal = (props: IProps & ModalProps) => {
  const [isSmallerThan450] = useMediaQuery('(max-width: 450px)');

  return (
    <Modal
      {...props}
      scrollBehavior={'inside'}
      isCentered={true}
      motionPreset={isSmallerThan450 ? 'slideInBottom' : 'scale'}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(4px)" />
      <ModalContent>
        {props.modalTitle && <ModalHeader>{props.modalTitle}</ModalHeader>}
        {props.closeButton && <ModalCloseButton />}
        <ModalBody pb={4} pt={0}>
          {props.children}
        </ModalBody>
        {props.modalFooter && (
          <ModalFooter w={'100%'} display={'unset'}>
            {props.modalFooter}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
