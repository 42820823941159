import { createReducer } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import * as Actions from './actions';

interface State {
  spTvlFor: {
    [key: string]: BigNumber;
  };
}

export const initialState: State = {
  spTvlFor: {},
};

export default createReducer(initialState, (builder) =>
  builder.addCase(
    Actions.updateSPTvl,
    (t, { payload: p }: { payload: Actions.SPTvlType }) => {
      t.spTvlFor = t.spTvlFor || {};
      const key = `${p.chainId}`;
      t.spTvlFor[key] = p.value;
    },
  ),
);
