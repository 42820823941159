import { Navigate, Route, Routes } from 'react-router-dom';
import Mint from '../pages/index.page';
import OverviewPage from '../pages/overview.page';
import React, { createContext } from 'react';
import { IColorsType } from '../utils/inteface';
import StabilityPage from '../pages/stability.page';

// @ts-ignore
export const ThemeContext: React.Context<{ themecolor: IColorsType }> =
  createContext({ themecolor: 'purple' });

const RedirectToMint = () => {
  return <Navigate to={`/mint`} replace />;
};

export const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<RedirectToMint />} />
      <Route path={'/mint'} element={<Mint />} />
      <Route path={'/positions'} element={<OverviewPage />} />
      <Route path={'/stability-pool'} element={<StabilityPage />} />
    </Routes>
  );
};

export default Navigation;
