import { Dispatch } from '@reduxjs/toolkit';

import { getSupportedTokens } from '../../ui-config/markets/marketConfig';
import { Protocol } from '../../protocol/Protocol';
import * as Actions from './actions';
import ERC20ABI from '../../protocol/deployments/abi/IERC20.json';
import Multicall from '../../protocol/deployments/abi/Multicall.json';
import { CallReturnContext } from 'ethereum-multicall';
import { BigNumber } from 'ethers';
import { addCall } from '../helpers';
import { getDisplayBalance } from '../../utils/formatBalance';

export const initUser = (
  core: Protocol,
  dispatch: Dispatch,
  chainId: number,
) => {
  _initPriceFeed(core, dispatch, chainId);
  _initETHBalance(core, dispatch, chainId);
  _initUser(core, dispatch, chainId);
};

const _initETHBalance = (
  core: Protocol,
  dispatch: Dispatch,
  chainId: number,
) => {
  const balanceOfETH = {
    methodName: 'getEthBalance',
    methodParameters: [core.myAccount],
    callback(event: CallReturnContext) {
      try {
        dispatch(
          Actions.updateBalanceOf({
            chainId,
            bal: BigNumber.from(event.returnValues[0]),
            who: core.myAccount,
            token: core._tokens[chainId]['ETH'].address,
          }),
        );
      } catch (e) {
        console.log('initUser _initETHBalance', e);
      }
    },
  };

  addCall(core, chainId, core._config[chainId].multicall3, Multicall, [
    balanceOfETH,
  ]);
};

const _initUser = (core: Protocol, dispatch: Dispatch, chainId: number) => {
  const contracts = Object.values(core._contracts[chainId]);

  for (const token of getSupportedTokens(chainId)) {
    if (token !== 'ETH') {
      const balanceOfCall = {
        methodName: 'balanceOf',
        methodParameters: [core.myAccount],
        callback(event: CallReturnContext) {
          try {
            dispatch(
              Actions.updateBalanceOf({
                chainId,
                bal: BigNumber.from(event.returnValues),
                who: core.myAccount,
                token: core._tokens[chainId][token].address,
              }),
            );
          } catch (e) {
            console.log('initUser balanceOf ', token, e);
          }
        },
      };

      const approvalCalls = contracts.map((contract) => ({
        methodName: 'allowance',
        methodParameters: [core.myAccount, contract.address],
        callback(event: CallReturnContext) {
          try {
            console.log('allowance', event.returnValues, token);
            dispatch(
              Actions.updateApprovalOf({
                chainId,
                approval: BigNumber.from(event.returnValues),
                to: event.methodParameters[1],
                from: event.methodParameters[0],
                token: core._tokens[chainId][token].address,
              }),
            );
          } catch (e) {
            console.log(
              'initUser approvalCalls',
              core._tokens[chainId][token].address,
              e,
            );
          }
        },
      }));

      // add mulitcalls
      addCall(core, chainId, core._tokens[chainId][token].address, ERC20ABI, [
        balanceOfCall,
        ...approvalCalls,
      ]);
    }
  }
};

const _initPriceFeed = (
  core: Protocol,
  dispatch: Dispatch,
  chainId: number,
) => {
  core._config[chainId]['priceFeedTokens'].forEach((token) => {
    if (token.symbol === 'ONEZ') {
      dispatch(
        Actions.updatePriceFeedOf({
          token: core._tokens[chainId][token.symbol].address,
          chainId: chainId,
          value: 1,
        }),
      );
    } else {
      const priceFeedCall = {
        methodName: 'latestAnswer',
        methodParameters: [],
        callback(e: CallReturnContext) {
          try {
            const valueInBN = BigNumber.from(e.returnValues);
            dispatch(
              Actions.updatePriceFeedOf({
                token: core._tokens[chainId][token.symbol].address,
                chainId: chainId,
                value: Number(getDisplayBalance(valueInBN, 8, 8)),
              }),
            );
          } catch (e) {
            console.log('initUser balanceOfCall', e);
          }
        },
      };
      addCall(
        core,
        chainId,
        token.chainLinkOracle,
        ['function latestAnswer() view returns (unit256)'],
        [priceFeedCall],
      );
    }
  });
};
