import { BigNumber } from 'ethers';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const MAX_UINT_256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
export const BNZERO = BigNumber.from(0);

export const MAX_TRIALS_AT_ONCE = 2500;
export const REDEEM_MAX_ITERATIONS = 70;

export const Decimals18 = BigNumber.from(10).pow(18);
