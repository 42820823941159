import { IColorsType } from '../utils/inteface';
import { MainLayout } from '../layouts/MainLayout';
import { ThemeContext } from '../navigation';
import Container from '../layouts/Container';
import PageHeader from '../layouts/PageHeader';
import SPDepositContent from '../modules/stabilityPool/SPDepositContent';
import SPWithdrawContent from '../modules/stabilityPool/SPWithdrawContent';
import SPInfo from '../modules/stabilityPool/SPInfo';
import UserSPInfo from '../modules/stabilityPool/UserSPInfo';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import ThemeBoxWrapper from '../components/ThemeBoxWrapper';

const StabilityPage = () => {
  const color: IColorsType = 'teal';

  return (
    <ThemeContext.Provider value={{ themecolor: color }}>
      <MainLayout>
        <Container>
          <PageHeader
            title={'Stability Pool'}
            desc={'some loreum ipsum comes here as a desc'}
            mb={8}
          />
          <SPInfo />
          <ThemeBoxWrapper themecolor={color} p={0}>
            <Tabs isFitted variant="enclosed">
              <TabList mb={4}>
                <Tab _selected={{ bg: color, color: 'white' }}>Deposit</Tab>
                <Tab _selected={{ bg: color, color: 'white' }}>Withdraw</Tab>
              </TabList>
              <TabPanels p={4}>
                <TabPanel p={0}>
                  <SPDepositContent />
                </TabPanel>
                <TabPanel p={0}>
                  <SPWithdrawContent />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ThemeBoxWrapper>
          {/*<SPWithdrawContent/>*/}
          <UserSPInfo />
        </Container>
      </MainLayout>
    </ThemeContext.Provider>
  );
};

export default StabilityPage;
