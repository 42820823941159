import { useCallback } from 'react';
import { useGetChainId } from '../useWagmiHooks';

import useCore from '../useCore';
import { useGetSignedContract } from '../useGetContract';
import { getDisplayBalance } from '../../utils/formatBalance';
import { BigNumber } from 'ethers';
import { useToast } from '@chakra-ui/react';
import formatErrorMessage from '../../utils/formatErrorMessage';

const useWithdrawFromSP = (amount: BigNumber) => {
  const core = useCore();
  const addToast = useToast();
  const chainId = useGetChainId();
  const spPool = core.getStabilityPool(chainId);

  const contract = useGetSignedContract(spPool.contract.address, spPool.abi);

  return useCallback(
    async (
      onInitiating: () => void,
      onSuccess: () => void,
      onFailure: (e: string) => void,
    ): Promise<void> => {
      try {
        onInitiating();
        addToast({
          title: 'Withdraw SP',
          description: `Withdrawing ${getDisplayBalance(
            amount,
            18,
            3,
          )} ONEZ from SP`,
          status: 'warning',
        });
        const response = await contract.withdrawFromSP(amount);
        const tx = await response.wait();

        if (tx?.status === 1) {
          addToast({
            title: 'Withdraw Successful',
            description: `Withdrawed ${getDisplayBalance(
              amount,
              18,
              3,
            )} ONEZ from SP`,
            status: 'success',
          });
          onSuccess();
        }
        if (tx?.status !== 1) {
          addToast({
            title: 'Error',
            description: `Error Occurred while opening a position`,
            status: 'error',
          });
          onFailure('');
        }
      } catch (e: any) {
        console.log('useWithdrawFromSP error', e);
        onFailure('');
        addToast({
          title: 'Error',
          description: formatErrorMessage(e?.data?.message || e?.message),
          status: 'error',
        });
      }
    },
    [addToast, amount, contract],
  );
};

export default useWithdrawFromSP;
