import { Box, Link, Text } from '@chakra-ui/react';
import { IColorsType } from '../utils/inteface';
import { MainLayout } from '../layouts/MainLayout';
import { ThemeContext } from '../navigation';
import { useNavigate } from 'react-router-dom';
import Container from '../layouts/Container';
import MintContent from '../modules/mint/MintContent';
import { troveDetailsOf, useQuery } from '../utils/helperFunctions';
import { getSupportedTrove } from '../ui-config/markets/marketConfig';
import { useGetChainId } from '../hooks/useWagmiHooks';
import PageHeader from '../layouts/PageHeader';

export default function Mint() {
  const color: IColorsType = 'purple';
  const navigate = useNavigate();
  const chainId = useGetChainId();
  const supportedTrove = getSupportedTrove(chainId);
  const query = useQuery();
  let trove = query.get('collateral');
  if (trove === null) trove = supportedTrove[0];
  const troveDetails = troveDetailsOf(trove || '', chainId);

  return (
    <ThemeContext.Provider value={{ themecolor: color }}>
      <MainLayout>
        <Container>
          <Box mt={16}>
            <PageHeader
              title={'The Self-Repaying Loan on L2s'}
              desc={
                "Take a stablecoin loan on your assets and watch it pay itself back with it's own yield."
              }
              mb={8}
            />
            {trove && supportedTrove.includes(trove) && troveDetails && (
              <MintContent trove={troveDetails} />
            )}
            <Box mt={6}>
              <Text align={'center'} color={`${color}.400`} mb={1}>
                <Link onClick={() => navigate('/airdrop')}>
                  Looking for an airdrop? View the leaderboard 🏅
                </Link>
              </Text>
              <Text align={'center'} color={`${color}.400`}>
                <Link
                  href={'https://forms.gle/jVYxRN5KTLqr8xDa8'}
                  target="_blank"
                >
                  Report bugs over at https://forms.gle/jVYxRN5KTLqr8xDa8
                </Link>
                {/* ⚠️ Protocol is undergoing audits. Please use at your own risk ⚠️ */}
              </Text>
            </Box>
          </Box>
        </Container>
      </MainLayout>
    </ThemeContext.Provider>
  );
}
