import { Box, HStack, Text } from '@chakra-ui/react';
import IconLoader from '../../components/Loader/IconLoader';
import { useTokenBalance } from '../../store/token/hooks';
import useCore from '../../hooks/useCore';
import { useGetChainId } from '../../hooks/useWagmiHooks';
import { getDisplayBalance } from '../../utils/formatBalance';

interface IProps {
  onTokenSelection: (token: string) => void;
  token: string;
}

const TokenSelectorRow = (props: IProps) => {
  const core = useCore();
  const chainId = useGetChainId();

  const tokenERC20 = core._tokens[chainId][props.token];
  const tokenBalance = useTokenBalance(chainId, tokenERC20.address);

  return (
    <HStack
      justifyContent={'space-between'}
      cursor={'pointer'}
      _hover={{ bgColor: '#00000010' }}
      transition={'0.2s ease-in-out'}
      p={2}
      borderRadius={'lg'}
      onClick={() => props.onTokenSelection(props.token)}
    >
      <HStack>
        <IconLoader
          iconname={tokenERC20.symbol}
          icontype={'tokens'}
          width={8}
        />
        <Box>
          <Text fontSize={14}>{tokenERC20.symbol}</Text>
          <Text fontSize={14} fontWeight={'bold'}>
            {props.token}
          </Text>
        </Box>
      </HStack>
      <Text>{getDisplayBalance(tokenBalance, tokenERC20.decimal, 3)}</Text>
    </HStack>
  );
};

export default TokenSelectorRow;
