import { Box, HStack, Text } from '@chakra-ui/react';
import ThemeBoxWrapper from '../../components/ThemeBoxWrapper';
import { useContext } from 'react';
import { ThemeContext } from '../../navigation';
import { useGetSPTvl } from '../../store/app/hooks';
import { useGetChainId } from '../../hooks/useWagmiHooks';
import { getCompactNumber, getDisplayBalance } from '../../utils/formatBalance';

const SPInfo = () => {
  const { themecolor } = useContext(ThemeContext);
  const chainId = useGetChainId();
  const spTvl = useGetSPTvl(chainId);

  return (
    <ThemeBoxWrapper themecolor={themecolor} mb={4}>
      <HStack>
        <Box flex={0.5} color={themecolor}>
          <Text
            textAlign={'center'}
            fontSize={{ base: 14, md: 18 }}
            opacity={0.8}
          >
            APR
          </Text>
          <HStack justifyContent={'center'} alignItems={'baseline'}>
            <Text
              textAlign={'center'}
              fontSize={{ base: 24, md: 32 }}
              fontFamily={'numbers'}
            >
              ---
            </Text>
            <Text fontSize={{ base: 18, md: 22 }}>%</Text>
          </HStack>
          <Text textAlign={'center'} fontSize={12} opacity={0.6}>
            Revealing Soon 💰
          </Text>
        </Box>
        <Box flex={0.5} color={themecolor}>
          <Text
            textAlign={'center'}
            fontSize={{ base: 14, md: 18 }}
            opacity={0.8}
          >
            Pool TVL
          </Text>
          <HStack justifyContent={'center'} alignItems={'baseline'}>
            <Text
              textAlign={'center'}
              fontSize={{ base: 24, md: 32 }}
              fontFamily={'numbers'}
            >
              {getCompactNumber(Number(getDisplayBalance(spTvl, 18, 3)))}
            </Text>
            <Text fontSize={{ base: 18, md: 22 }}>ONEZ</Text>
          </HStack>
        </Box>
      </HStack>
      {/*<Box height={'2px'} bgColor={"gray.50"} mt={3} mb={3}/>
      <HStack>
        <Box flex={0.5} color={themecolor}>
          <Text textAlign={'center'} fontSize={18}>
            Your Deposit
          </Text>
          <Text textAlign={'center'} fontSize={32} fontFamily={'numbers'}>
            1,000 ONEZ
          </Text>
        </Box>
        <Box flex={0.5} color={themecolor}>
          <Text textAlign={'center'} fontSize={18}>
            Claimable Rewards
          </Text>
          <Text textAlign={'center'} fontSize={32} fontFamily={'numbers'}>
            $1
          </Text>
        </Box>
      </HStack>*/}
    </ThemeBoxWrapper>
  );
};

export default SPInfo;
