import { Box, Input, InputProps } from '@chakra-ui/react';
import { useContext } from 'react';
import { ThemeContext } from '../../navigation';

export interface IInputField extends InputProps {
  onValueChange: (value: string) => void;
  disableEnforcer?: boolean;
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const InputField = (props: IInputField) => {
  const { themecolor } = useContext(ThemeContext);

  const enforcer = (nextUserInput: string) => {
    if (props.disableEnforcer) {
      props.onValueChange(nextUserInput);
      return;
    }

    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      props.onValueChange(nextUserInput);
    }
  };
  return (
    <Box display={'flex'} alignItems={'center'} flex={1}>
      <Input
        {...props}
        value={props.value}
        onChange={(event) => enforcer(event.target.value)}
        size={{ base: 'md', md: 'lg' }}
        variant={'Unstyled'}
        bgColor={'transparent'}
        pl={0}
        _placeholder={{ opacity: 0.4, color: `${themecolor}.400` }}
        color={`${themecolor}.500`}
        fontFamily={'numbers'}
        fontWeight={'bold'}
        fontSize={{ base: '2xl', md: '3xl' }}
        width={'100%'}
      />
    </Box>
  );
};

export default InputField;
