import { createReducer } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

import * as Actions from './actions';

interface State {
  balanceOf: {
    [token: string]: {
      [who: string]: BigNumber;
    };
  };
  approvals: {
    [token: string]: {
      [from: string]: {
        [to: string]: BigNumber;
      };
    };
  };
  priceFeed: {
    [token: string]: number;
  };
}

export const initialState: State = {
  balanceOf: {},
  approvals: {},
  priceFeed: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      Actions.updateBalanceOf,
      (t, { payload: p }: { payload: Actions.BalanceOfType }) => {
        t.balanceOf = t.balanceOf || {};
        t.balanceOf[`${p.chainId}:${p.token}`] =
          t.balanceOf[`${p.chainId}:${p.token}`] || {};
        t.balanceOf[`${p.chainId}:${p.token}`][p.who] = p.bal;
      },
    )
    .addCase(
      Actions.updateApprovalOf,
      (t, { payload: p }: { payload: Actions.ApprovalOfType }) => {
        t.approvals = t.approvals || {};
        t.approvals[`${p.chainId}:${p.token}`] =
          t.approvals[`${p.chainId}:${p.token}`] || {};
        t.approvals[`${p.chainId}:${p.token}`][p.from] =
          t.approvals[`${p.chainId}:${p.token}`][p.from] || {};
        t.approvals[`${p.chainId}:${p.token}`][p.from][p.to] = p.approval;
      },
    )
    .addCase(
      Actions.updatePriceFeedOf,
      (t, { payload: p }: { payload: Actions.PriceFeedType }) => {
        t.priceFeed = t.priceFeed || {};
        t.priceFeed[`${p.chainId}:${p.token}`] = p.value;
      },
    ),
);
