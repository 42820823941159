import { Dispatch } from '@reduxjs/toolkit';
import { Protocol } from '../../protocol/Protocol';
import * as Actions from './actions';
import { CallReturnContext } from 'ethereum-multicall';
import { ethers } from 'ethers';
import { addCall } from '../helpers';

export const initApp = (
  core: Protocol,
  dispatch: Dispatch,
  chainId: number,
) => {
  initSP(core, dispatch, chainId);
};

const initSP = (core: Protocol, dispatch: Dispatch, chainId: number) => {
  const depositCall = {
    methodName: 'balanceOf',
    methodParameters: [core.getStabilityPool(chainId).contract.address],
    callback(e: CallReturnContext) {
      try {
        const abiCoder = ethers.utils.defaultAbiCoder.decode(
          ['uint256'],
          e.returnValues,
        );
        dispatch(
          Actions.updateSPTvl({
            chainId: chainId,
            value: abiCoder[0],
          }),
        );
      } catch (e) {
        console.log('initSP error', e);
      }
    },
  };

  addCall(
    core,
    chainId,
    core.getERC20Token(chainId, 'ONEZ').token.address,
    core.getERC20Token(chainId, 'ONEZ').abi,
    [depositCall],
  );
};
