import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Box, Link, Text, useMediaQuery } from '@chakra-ui/react';
import { useContext } from 'react';
import { ThemeContext } from '../navigation';
import { useNavigate } from 'react-router-dom';

export default function AppHeader() {
  const { themecolor } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [isSmallerThan450] = useMediaQuery('(max-width: 450px)');

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      px={'4'}
      py={'2'}
      alignItems={'center'}
      position={'fixed'}
      w={'100%'}
      zIndex={'docked'}
      backdropFilter={'blur(5px)'}
    >
      <Box
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'baseline'}
      >
        <Text
          fontFamily={'heading'}
          fontSize={32}
          color={`${themecolor}.500`}
          onClick={() => navigate('/')}
          cursor={'pointer'}
        >
          ONEZ.cash
        </Text>
        <Box
          display={{ base: 'none', md: 'flex' }}
          flex={1}
          justifyContent={'center'}
          color={`${themecolor}.500`}
          ml={8}
          fontSize={22}
        >
          <Link
            _hover={{ outline: 'none', opacity: 0.7 }}
            href={'/#/positions'}
            fontFamily={'heading'}
            mr={4}
          >
            Positions
          </Link>
          <Link
            _hover={{ outline: 'none', opacity: 0.7 }}
            href={'/#/stability-pool'}
            fontFamily={'heading'}
          >
            Stability Pool
          </Link>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
        {/*<Link mr={4} href={'/'}>
          <Button colorScheme={themecolor} size={'sm'}>
            IPFS Web <ExternalLinkIcon mx="2px" />
          </Button>
        </Link>*/}
        {isSmallerThan450 ? (
          <ConnectButton
            showBalance={false}
            accountStatus={'avatar'}
            chainStatus={'icon'}
          />
        ) : (
          <ConnectButton />
        )}
      </Box>
    </Box>
  );
}
