import React, { ReactNode } from 'react';

import '../App.css';
import '../static/fonts/pixeboy-font/pixeboy.css';
import '@rainbow-me/rainbowkit/styles.css';

import { ChakraProvider } from '@chakra-ui/react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { customTheme } from '../static/ChakraTheme';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { HashRouter as Router } from 'react-router-dom';
import { myCustomTheme } from '../static/RainbowTheme';
import { publicProvider } from 'wagmi/providers/public';
import Navigation from '../navigation';
import { ProtocolProvider } from '../context/ProtocolProvider';
import { Provider } from 'react-redux';
import store from '../store';
import { ConfigChains } from '../ui-config/markets/marketConfig';
import Updaters from '../store/Updaters';
import { ToastProviderProps } from '@chakra-ui/toast';

const ToastOptions: ToastProviderProps = {
  defaultOptions: {
    position: 'top-right',
    isClosable: true,
    variant: 'left-accent',
  },
};

const RainbowProvider = ({ children }: React.HTMLProps<HTMLBaseElement>) => {
  const { chains, provider } = configureChains(ConfigChains, [
    publicProvider(),
  ]);

  const { connectors } = getDefaultWallets({
    appName: 'ONEZ.cash',
    chains: chains,
    projectId: 'cfc12c6c49d329681212a698c47c01ef',
  });

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        showRecentTransactions={true}
        coolMode={true}
        theme={myCustomTheme}
      >
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export function Providers({ children }: { children: ReactNode }) {
  return (
    <>
      <Provider store={store}>
        <RainbowProvider>
          <ChakraProvider theme={customTheme} toastOptions={ToastOptions}>
            <ProtocolProvider>{children}</ProtocolProvider>
          </ChakraProvider>
        </RainbowProvider>
      </Provider>
    </>
  );
}

export default function App() {
  return (
    <Providers>
      <Router>
        <Navigation />
        <Updaters />
      </Router>
    </Providers>
  );
}
