import { lightTheme, Theme } from '@rainbow-me/rainbowkit';

export const myCustomTheme: Theme = {
  // lightMode: {
  ...lightTheme(),
  colors: {
    ...lightTheme().colors,
    accentColor: '#7A54FF',
  },
  // },
  /*darkMode: {
    ...darkTheme(),
    colors: {
      ...darkTheme().colors,
      accentColor: "#7A54FF",
    },
  },*/
};
