import Modal from '../index';
import { IModalProps } from '../../utils/inteface';
import { Box, Text } from '@chakra-ui/react';
import { useGetChainId } from '../../hooks/useWagmiHooks';
import TokenSelectorRow from './TokenSelectorRow';
import { getDepositTokensOfAllTroves } from '../../utils/helperFunctions';

interface IProps {
  onTokenSelection: (token: string) => void;
}

const TokenSelectorModal = (props: IModalProps & IProps) => {
  const chainId = useGetChainId();
  const tokens = getDepositTokensOfAllTroves(chainId);

  return (
    <Modal
      isOpen={props.open}
      onClose={props.onClose}
      closeButton
      modalTitle={<Text fontSize={14}>Select a Token</Text>}
    >
      <Box>
        {tokens.map((trove) => {
          return (
            <TokenSelectorRow
              onTokenSelection={props.onTokenSelection}
              token={trove}
              key={trove}
            />
          );
        })}
      </Box>
    </Modal>
  );
};

export default TokenSelectorModal;
