import { ReactNode } from 'react';
import AppHeader from './AppHeader';
import { Box } from '@chakra-ui/react';
import HamburgerMenu from './HamburgerMenu';
import AppFooter from './AppFooter';

export function MainLayout({ children }: { children: ReactNode }) {
  return (
    <Box
      flexDirection={'column'}
      position={'relative'}
      display={'flex'}
      flex={1}
    >
      <AppHeader />
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        py={8}
        flex={1}
      >
        <Box>
          <Box px={{ md: 6, sm: 4 }} p={'2'} pt={14}>
            {children}
          </Box>
        </Box>
      </Box>
      <AppFooter />
      <HamburgerMenu />
    </Box>
  );
}
