import { Box, Link, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { IModalProps } from '../utils/inteface';
import { useNavigate } from 'react-router-dom';
import CustomModal from './index';

const SiteMenu = (props: IModalProps) => {
  const navigate = useNavigate();

  return (
    <CustomModal
      isOpen={props.open}
      onClose={props.onClose}
      size={{ base: 'lg', md: 'xs' }}
      modalTitle={'Links'}
      closeButton
    >
      <Box>
        <Text mb={3} display={{ base: 'block', md: 'none' }}>
          <Link onClick={() => navigate('/')}>Home</Link>
        </Text>

        <Text mb={3} display={{ base: 'block', md: 'none' }}>
          <Link onClick={() => navigate('/positions')}>Positions</Link>
        </Text>

        <Text mb={3} display={{ base: 'block', md: 'none' }}>
          <Link onClick={() => navigate('/stability-pool')}>
            Stability Pool
          </Link>
        </Text>

        <Text mb={3}>
          <Link href="https://docs.onez.cash" isExternal>
            Documentation <ExternalLinkIcon mx="2px" />
          </Link>
        </Text>
        <Text mb={3}>
          <Link href="https://github.com/zerolend/onez-v2" isExternal>
            Github <ExternalLinkIcon mx="2px" />
          </Link>
        </Text>
        <Text mb={3}>
          <Link href="https://zerolend.xyz" isExternal>
            ZeroLend <ExternalLinkIcon mx="2px" />
          </Link>
        </Text>
      </Box>
    </CustomModal>
  );
};

export default SiteMenu;
