import { Box, Icon, Text } from '@chakra-ui/react';
import { FaAngleDown } from 'react-icons/fa6';
import IconLoader from '../Loader/IconLoader';
import TokenSelectorModal from '../../modals/TokenSelectorModal/TokenSelectorModal';
import { useState } from 'react';

interface IProps {
  selectedToken: string;
  setSelectedToken?: (token: string) => void;
}

const Collateral = (props: IProps) => {
  const [openSelectorModal, setOpenSelectorModal] = useState<boolean>(false);
  return (
    <Box
      bgColor={'white'}
      borderRadius={'3xl'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      px={4}
      cursor={'pointer'}
      onClick={() => setOpenSelectorModal(true)}
    >
      {props.setSelectedToken && openSelectorModal && (
        <TokenSelectorModal
          open={openSelectorModal}
          onClose={() => setOpenSelectorModal(false)}
          onTokenSelection={(token: string) => {
            if (props.setSelectedToken) props.setSelectedToken(token);
            setOpenSelectorModal(false);
          }}
        />
      )}
      <IconLoader
        iconname={props.selectedToken}
        icontype={'tokens'}
        mr={1}
        width={{ base: 4, md: 6 }}
      />
      <Text fontWeight={'bold'} fontSize={{ base: 14, md: 16 }}>
        {props.selectedToken}
      </Text>
      {props.setSelectedToken && <Icon as={FaAngleDown} ml={1}></Icon>}
    </Box>
  );
};

export default Collateral;
