import { Configuration, IAddresses, TroveDetails } from '../../utils/inteface';
import config from '../../protocol/deployments/blastSepolia.json';

const ADDRESSES: IAddresses = {
  BORROWER_OPERATION: config.BorrowerOperations.address,
  DEBT_TOKEN_PROXY: config.DebtTokenOnezProxy.address,
  STABILITY_POOL: config.StabilityPool.address,
  ETH_TROVE: config.TroveManagerWETH.address,
  ETH_DELEGATE: config.WETHDelegateWETH.address,
};

const PRICE_FEED_TOKENS = [
  {
    symbol: 'WETH',
    chainLinkOracle: '0x65fc5280E838751eDafB6f171391FE0AC5C1d05a',
  },
  {
    symbol: 'ETH',
    chainLinkOracle: '0x65fc5280E838751eDafB6f171391FE0AC5C1d05a',
  },
  {
    symbol: 'ONEZ',
    chainLinkOracle: '',
  },
];

const TROVES: TroveDetails[] = [
  {
    trove: 'ETH',
    troveAddress: ADDRESSES['ETH_TROVE'] || '',
    depositToken: 'ETH',
    displayName: 'ETH',
    crParameters: {
      mcr: 110,
      ccr: 150,
    },
    liquidationReserve: 10,
    minDebtAmount: 20,
    oneTimeFee: 1,
  },
  // {
  //   trove: 'USDC',
  //   troveAddress: ADDRESSES['USDC_TROVE'],
  //   depositToken: 'USDC',
  //   displayName: 'USDC',
  //   crParameters: {
  //     mcr: 110,
  //     ccr: 150,
  //   },
  //   liquidationReserve: 5,
  //   minDebtAmount: 200,
  //   oneTimeFee: 1,
  // },
];

const DECIMALS_OVERRIDES = { USDC: 6 };

export const blastSepolia: Configuration = {
  chainId: 168587773,
  marketName: 'Blast Sepolia',
  marketIcon: require('../../static/icons/networks/ETH.svg'),
  explorerUrl: 'https://testnet.blastscan.io/',
  defaultProvider: 'https://sepolia.blast.io',
  deployments: require('../../protocol/deployments/blastSepolia.json'),
  priceFeedTokens: PRICE_FEED_TOKENS,
  troves: TROVES,
  multicall3: '0xcA11bde05977b3631167028862bE2a173976CA11',
  decimalOverrides: DECIMALS_OVERRIDES,
  addresss: ADDRESSES,
};

export const blastSepoliaChain: any = {
  id: 168587773,
  name: 'Blast Sepolia',
  network: 'blast-sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: [blastSepolia.defaultProvider],
      // webSocket: ['wss://mainnet.era.zksync.io/ws'];
    },
    public: {
      http: [blastSepolia.defaultProvider],
      // webSocket: ['wss://mainnet.era.zksync.io/ws'];
    },
  },
  blockExplorers: {
    default: {
      name: 'blastscan',
      url: blastSepolia.explorerUrl,
    },
  },
  contracts: {
    multicall3: {
      address: blastSepolia.multicall3,
    },
  },
};
