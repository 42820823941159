import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import { useEffect } from 'react';

interface IProps {
  from: number;
  to: number;
  duration: number;
  decimals: number;
}

const AnimatingNumber = (props: IProps) => {
  const count = useMotionValue(props.from);
  const rounded = useTransform(count, (latest: number) =>
    latest.toFixed(props.decimals),
  );

  useEffect(() => {
    const controls = animate(count, props.to, { duration: props.duration });
    return controls.stop;
  }, [count, props.duration, props.to]);

  return <motion.p>{rounded}</motion.p>;
};

export default AnimatingNumber;
