import { Configuration } from '../../utils/inteface';
import { zkSync as zkSyncChain, zkSyncTestnet } from 'wagmi/chains';
import { zkSyncGoerli } from './zkSync-goerli';
import { zkSync } from './zkSync';
import { blastSepolia, blastSepoliaChain } from './blast-sepolia';

const configurations: { [env: string]: Configuration } = {
  // [zkSyncGoerli.chainId]: zkSyncGoerli,
  [blastSepolia.chainId]: blastSepolia,
  // 324: zkSync,
};

export const ConfigChains = [blastSepoliaChain];

export const getSupportedChains = (): number[] =>
  Object.keys(configurations).map((i) => Number(i));

export const getSupportedTrove = (chainId: number): string[] => {
  return Object.values(configurations[chainId]['troves']).map(
    (value) => value.trove,
  );
};

export const getSupportedTokens = (chainId: number): string[] => {
  return Object.values(configurations[chainId]['priceFeedTokens']).map(
    (value) => value.symbol,
  );
};

export default configurations;
