import { createAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

export type ISP = {
  deposit: BigNumber;
  rewards: {
    [key: string]: BigNumber;
  };
};

export type SPDepositForType = {
  chainId: number;
  who: string;
  amount: BigNumber;
};

export type SPRewardsForType = {
  chainId: number;
  who: string;
  rewards: {
    [key: string]: BigNumber;
  };
};

export const SPInitial: ISP = {
  deposit: BigNumber.from(0),
  rewards: {},
};

export const updateDepositFor = createAction<SPDepositForType>(
  'user/updateDepositFor',
);

export const updateRewardsFor = createAction<SPRewardsForType>(
  'user/updateRewardsFor',
);
