import { Box, Button, InputGroup } from '@chakra-ui/react';
import { useContext, useMemo, useState } from 'react';
import { ThemeContext } from '../../navigation';
import InputContainer from '../../components/Input/InputContainer';
import {
  formatToBN,
  getDisplayBalance,
  getTokenToUSD,
} from '../../utils/formatBalance';
import { ITxStatus } from '../../utils/inteface';
import { useGetChainId } from '../../hooks/useWagmiHooks';
import {
  useTokenBalance,
  useTokenPriceOfByAddress,
} from '../../store/token/hooks';
import Collateral from '../../components/Input/Collateral';
import InputField from '../../components/Input/Input';
import useApprove, { ApprovalState } from '../../hooks/callbacks/useApprove';
import useCore from '../../hooks/useCore';
import useDepositIntoSP from '../../hooks/callbacks/useDepositIntoSP';

const SPDepositContent = () => {
  const { themecolor } = useContext(ThemeContext);
  const core = useCore();
  const chainId = useGetChainId();

  const [value, setValue] = useState<string>('');
  const [txStatus, setTxStatus] = useState<ITxStatus>('initial');

  const onezERC20 = core._tokens[chainId]['ONEZ'];
  const onezBalance = useTokenBalance(
    chainId,
    core._tokens[chainId]['ONEZ'].address,
  );
  const debtInUSD = useTokenPriceOfByAddress(chainId, onezERC20.address);
  const debtValueInUSD = getTokenToUSD(Number(value), debtInUSD);

  //For Approving
  const [approveStatus, approve] = useApprove(
    chainId,
    onezERC20,
    core.getDebtTokenProxy(chainId).contract.address,
    formatToBN(value, onezERC20.decimal),
  );
  const isApproved = approveStatus === ApprovalState.APPROVED;
  const isApproving = approveStatus === ApprovalState.PENDING;

  const depositIntoSP = useDepositIntoSP(formatToBN(value, onezERC20.decimal));

  const handleDeposit = () => {
    depositIntoSP(
      () => {
        setTxStatus('in-progress');
      },
      () => {
        setTxStatus('successful');
      },
      () => {
        setTxStatus('not-successful');
      },
    );
  };

  const disableNext = useMemo(() => {
    return (
      !Number(value) || formatToBN(value, onezERC20.decimal).gt(onezBalance)
    );
  }, [onezBalance, onezERC20.decimal, value]);

  return (
    <Box>
      {/*<SuccessModal open={true} onClose={() => {}} title={'Success'} description={'You have loreum ipsum and something something. You have loreum somehting  '} ctaButton={{text: 'Hello', onPress: () => {}}}/>*/}
      <InputContainer
        label={'Provide ONEZ to earn liquidation rewards'}
        mb={4}
        data={`Balance: ${getDisplayBalance(
          onezBalance,
          onezERC20.decimal,
          3,
        )}`}
        footer={`$${debtValueInUSD.toFixed(3)}`}
      >
        <InputGroup>
          <InputField
            value={value}
            onValueChange={setValue}
            type="number"
            placeholder={'0.00'}
          />
          <Collateral selectedToken={'ONEZ'} />
        </InputGroup>
      </InputContainer>
      <Box mt={8}>
        {!isApproved ? (
          <Button
            colorScheme={disableNext && value !== '' ? 'red' : `${themecolor}`}
            w={'100%'}
            size="lg"
            borderRadius={'xl'}
            isLoading={isApproving}
            loadingText={`Approving ${'ONEZ'}`}
            onClick={approve}
            isDisabled={disableNext}
          >
            Approve {'ONEZ'}
          </Button>
        ) : (
          <Button
            colorScheme={disableNext && value !== '' ? 'red' : `${themecolor}`}
            w={'100%'}
            size="lg"
            borderRadius={'xl'}
            isLoading={txStatus === 'in-progress'}
            loadingText={'Depositing'}
            onClick={handleDeposit}
            isDisabled={disableNext}
          >
            Deposit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SPDepositContent;
