import { BigNumber, ethers } from 'ethers';
import { useCallback, useMemo, useState } from 'react'; // import { useOnSuccess } from "../../helpers/helperFunctions";
import ERC20 from '../../protocol/ERC20';
import { useTransactionAdder } from '../../store/transactions/hooks';
import useAllowance from '../state/useAllowance';
import { useToast } from '@chakra-ui/react';
import useCore from '../useCore';

export const APPROVE_AMOUNT: BigNumber = ethers.constants.MaxUint256;
export const APPROVE_BASE_AMOUNT = BigNumber.from('1000000000000000000000000');

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

/**
 * Returns a variable indicating the state of the approval and a function which
 * approves if necessary or early returns.
 */
function useApprove(
  chainId: number,
  token: ERC20,
  spender: string,
  amount: BigNumber,
): [ApprovalState, () => Promise<void>] {
  const [approving, setApproving] = useState<boolean>(false);
  const addToast = useToast();
  const currentAllowance = useAllowance(chainId, token, spender);

  const core = useCore();
  const signedToken = core._tokens[chainId];

  // Check the current approval status.
  const approvalState: ApprovalState = useMemo(() => {
    if (token.symbol === 'ETH') return ApprovalState.APPROVED;
    // We might not have enough data to know whether or not we need to approve.
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    if (approving) return ApprovalState.PENDING;
    // The amountToApprove will be defined if currentAllowance is.
    return BigNumber.from(currentAllowance).lt(amount)
      ? ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [amount, approving, currentAllowance, token.symbol]);

  const addTransaction = useTransactionAdder();
  // const onSuccess = useOnSuccess;

  const approve = useCallback(async (): Promise<void> => {
    if (
      approvalState !== ApprovalState.NOT_APPROVED &&
      approvalState !== ApprovalState.UNKNOWN
    ) {
      console.error('Approve was called unnecessarily');
      return;
    }

    setApproving(true);
    const response = await token
      .connect(core._signer)
      .approve(spender, APPROVE_AMOUNT);

    addTransaction(response, {
      summary: `Approve ${token.symbol}`,
      approval: {
        tokenAddress: token.address,
        spender: spender,
      },
    });
    setApproving(false);
    addToast({
      title: 'Approve',
      description: `Approving ${token.symbol}`,
      status: 'warning',
    });

    const tx = await response.wait();

    if (tx?.status === 1) {
      addToast({
        title: 'Approved',
        description: `Apporved ${token.symbol}`,
        status: 'success',
      });
    }
  }, [
    approvalState,
    signedToken,
    spender,
    addTransaction,
    token.symbol,
    token.address,
    addToast,
  ]);

  return [approvalState, approve];
}

export default useApprove;
