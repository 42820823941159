import { useMemo } from 'react';
import { BigNumber } from 'ethers';
import { getTokenBNToUSD } from '../../utils/formatBalance';
import { useTokenPriceOfBySymbol } from '../../store/token/hooks';
import { useGetChainId } from '../useWagmiHooks';
import useCore from '../useCore';

const useGetCollateralRatio = (
  collateral: string,
  collateralAmount: BigNumber,
  debtAmount: BigNumber,
) => {
  const chainId = useGetChainId();
  const core = useCore();

  const debtERC20 = core._tokens[chainId]['ONEZ'];
  const debtInUSD = useTokenPriceOfBySymbol(chainId, 'ONEZ', core);
  const debtAmountInUSD = getTokenBNToUSD(
    debtAmount,
    debtInUSD,
    debtERC20.decimal,
  );

  const collateralERC20 = core._tokens[chainId][collateral];
  const collateralInUSD = useTokenPriceOfBySymbol(chainId, collateral, core);
  const collateralAmountInUSD = getTokenBNToUSD(
    collateralAmount,
    collateralInUSD,
    collateralERC20.decimal,
  );

  return useMemo(() => {
    return (collateralAmountInUSD * 100) / debtAmountInUSD || 0;
  }, [collateralAmountInUSD, debtAmountInUSD]);
};

export default useGetCollateralRatio;
