import React, { useEffect, useState } from 'react';

import DefaultIcon from '../../static/icons/Default.svg';
import { Image, ImageProps } from '@chakra-ui/react';

export interface IconLoaderProps {
  iconname: string;
  icontype?: 'tokens' | 'pools';
  onClick?: () => void;
}

const IconLoader = (props: IconLoaderProps & ImageProps) => {
  const { iconname, icontype = 'misc', onClick } = props;

  const [Icon, setIcon] = useState<string>(DefaultIcon);

  useEffect(() => {
    import(`../../static/icons/${icontype}/${iconname}.svg`)
      .then(async (image) => {
        setIcon(image.default);
      })
      .catch(() => {
        setIcon(DefaultIcon);
      });
  }, [iconname, icontype]);

  return (
    <Image
      {...props}
      src={Icon}
      alt={iconname}
      onClick={() => {
        if (onClick) onClick();
      }}
    />
  );
};

export default IconLoader;
