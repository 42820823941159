import ERC20 from '../../protocol/ERC20';
import useCore from '../useCore';
import { useTokenApprovalOf } from '../../store/token/hooks';

const useAllowance = (chainId: number, token: ERC20, spender: string) => {
  const core = useCore();

  const allowance = useTokenApprovalOf(
    chainId,
    token.address,
    core.myAccount,
    spender,
  );

  return allowance;
};

export default useAllowance;
