import { Box, Button, InputGroup } from '@chakra-ui/react';
import { useContext, useMemo, useState } from 'react';
import { ThemeContext } from '../../navigation';
import InputContainer from '../../components/Input/InputContainer';
import {
  formatToBN,
  getDisplayBalance,
  getTokenToUSD,
} from '../../utils/formatBalance';
import InputField from '../../components/Input/Input';
import Collateral from '../../components/Input/Collateral';
import useCore from '../../hooks/useCore';
import { useGetAccount, useGetChainId } from '../../hooks/useWagmiHooks';
import useApprove, { ApprovalState } from '../../hooks/callbacks/useApprove';
import { ITxStatus } from '../../utils/inteface';
import useWithdrawFromSP from '../../hooks/callbacks/useWithdrawFromSP';
import { useSPDepositFor } from '../../store/stability/hooks';
import { useTokenPriceOfByAddress } from '../../store/token/hooks';

const SPWithdrawContent = () => {
  const { themecolor } = useContext(ThemeContext);
  const core = useCore();
  const chainId = useGetChainId();
  const account = useGetAccount();
  const spDepositInfo = useSPDepositFor(chainId, account || '');

  const [value, setValue] = useState<string>('');
  const [txStatus, setTxStatus] = useState<ITxStatus>('initial');

  const onezERC20 = core._tokens[chainId]['ONEZ'];
  const onezDBalance = spDepositInfo;
  const debtInUSD = useTokenPriceOfByAddress(chainId, onezERC20.address);
  const debtValueInUSD = getTokenToUSD(Number(value), debtInUSD);

  //For Approving
  const [approveStatus, approve] = useApprove(
    chainId,
    onezERC20,
    core.getDebtTokenProxy(chainId).contract.address,
    formatToBN(value, onezERC20.decimal),
  );
  // const isApproved = approveStatus === ApprovalState.APPROVED;
  const isApproved = true;
  const isApproving = approveStatus === ApprovalState.PENDING;

  const withdrawFromSP = useWithdrawFromSP(
    formatToBN(value, onezERC20.decimal),
  );

  const handleWithdraw = () => {
    withdrawFromSP(
      () => {
        setTxStatus('in-progress');
      },
      () => {
        setTxStatus('successful');
      },
      () => {
        setTxStatus('not-successful');
      },
    );
  };

  const disableNext = useMemo(() => {
    return (
      !Number(value) || formatToBN(value, onezERC20.decimal).gt(onezDBalance)
    );
  }, [onezDBalance, onezERC20.decimal, value]);

  return (
    <Box>
      <InputContainer
        label={'Withdraw ONEZ from the stablility pool'}
        mb={4}
        data={`Available: ${getDisplayBalance(
          onezDBalance,
          onezERC20.decimal,
          3,
        )}`}
        footer={`$${debtValueInUSD.toFixed(3)}`}
      >
        <InputGroup>
          <InputField
            value={value}
            onValueChange={setValue}
            type="number"
            placeholder={'0.00'}
          />
          <Collateral selectedToken={'ONEZ'} />
        </InputGroup>
      </InputContainer>
      <Box mt={8}>
        {!isApproved ? (
          <Button
            colorScheme={disableNext && value !== '' ? 'red' : `${themecolor}`}
            w={'100%'}
            size="lg"
            borderRadius={'xl'}
            isLoading={isApproving}
            loadingText={`Approving ${'ONEZ'}`}
            onClick={approve}
            isDisabled={disableNext}
          >
            Approve {'ONEZ'}
          </Button>
        ) : (
          <Button
            colorScheme={disableNext && value !== '' ? 'red' : `${themecolor}`}
            w={'100%'}
            size="lg"
            borderRadius={'xl'}
            isLoading={txStatus === 'in-progress'}
            loadingText={'Depositing'}
            onClick={handleWithdraw}
            isDisabled={disableNext}
          >
            Withdraw
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SPWithdrawContent;
