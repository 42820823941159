import InputContainer from '../../components/Input/InputContainer';
import { Box, Button, InputGroup, Link, Text } from '@chakra-ui/react';
import InputField from '../../components/Input/Input';
import Collateral from '../../components/Input/Collateral';
import { useContext, useMemo } from 'react';
import {
  useTokenBalance,
  useTokenPriceOfByAddress,
} from '../../store/token/hooks';
import { useGetChainId } from '../../hooks/useWagmiHooks';
import useCore from '../../hooks/useCore';
import {
  formatToBN,
  getDisplayBalance,
  getTokenToUSD,
} from '../../utils/formatBalance';
import { ThemeContext } from '../../navigation';
import { TroveDetails } from '../../utils/inteface';
import useApprove, { ApprovalState } from '../../hooks/callbacks/useApprove';

interface IProps {
  selectedCollateral: string;
  setSelectedCollateral: (token: string) => void;
  debtAmount: string;
  setDebtAmount: (value: string) => void;
  value: string;
  setValue: (value: string) => void;
  trovePresent: boolean;
  trove: TroveDetails;
  setStep: (step: 1 | 2 | 3) => void;
}

const TakeCollateral = (props: IProps) => {
  const { themecolor } = useContext(ThemeContext);
  const {
    trove,
    trovePresent,
    selectedCollateral,
    value,
    setSelectedCollateral,
    setValue,
  } = props;
  const core = useCore();
  const chainId = useGetChainId();

  const collateralERC20 = core._tokens[chainId][selectedCollateral];
  const collateralInUSD = useTokenPriceOfByAddress(
    chainId,
    collateralERC20.address,
  );
  const tokenBalance = useTokenBalance(chainId, collateralERC20.address);
  const collateralAmountInUSD = getTokenToUSD(Number(value), collateralInUSD);

  //For Approving
  const [approveStatus, approve] = useApprove(
    chainId,
    collateralERC20,
    core.getDelegateContractOf(chainId, trove.trove).contract.address,
    formatToBN(props.value, collateralERC20.decimal),
  );
  const isApproved = approveStatus === ApprovalState.APPROVED;
  const isApproving = approveStatus === ApprovalState.PENDING;

  const disableNext = useMemo(() => {
    return (
      !Number(value) ||
      formatToBN(value, collateralERC20.decimal).gt(tokenBalance) ||
      trovePresent
    );
  }, [tokenBalance, collateralERC20.decimal, trovePresent, value]);

  return (
    <Box>
      <InputContainer
        label={'Provide collateral to earn yield'}
        mb={4}
        data={`Balance: ${getDisplayBalance(
          tokenBalance,
          collateralERC20.decimal,
          3,
        )}`}
        footer={`$${collateralAmountInUSD.toFixed(3)}`}
      >
        <InputGroup>
          <InputField
            value={value}
            onValueChange={setValue}
            type="number"
            placeholder={'0.00'}
          />
          <Collateral
            selectedToken={selectedCollateral}
            setSelectedToken={setSelectedCollateral}
          />
        </InputGroup>
      </InputContainer>
      <Box mt={8}>
        {trovePresent && (
          <Text mb={2} textAlign={'center'}>
            You Already have a trove opened with {selectedCollateral} view your
            position{' '}
            <Link href={`/#/position?collateral=${trove.trove}`}>here</Link>
          </Text>
        )}
        {!isApproved ? (
          <Button
            // colorScheme={(disableNext && value !== '') || trovePresent ? 'red' : `${themecolor}`}
            colorScheme={themecolor}
            w={'100%'}
            size="lg"
            borderRadius={'xl'}
            isLoading={isApproving}
            loadingText={`Approving ${selectedCollateral}`}
            onClick={approve}
            isDisabled={disableNext}
          >
            Approve {selectedCollateral}
          </Button>
        ) : (
          <Button
            // colorScheme={(disableNext && value !== '') || trovePresent ? 'red' : `${themecolor}`}
            colorScheme={themecolor}
            w={'100%'}
            size="lg"
            borderRadius={'xl'}
            onClick={() => props.setStep(2)}
            isDisabled={disableNext}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default TakeCollateral;
