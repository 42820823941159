import { createReducer } from '@reduxjs/toolkit';

import * as Actions from './actions';
import { ISP } from './actions';

interface State {
  spFor: {
    [spKey: string]: {
      [who: string]: ISP;
    };
  };
}

export const initialState: State = {
  spFor: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      Actions.updateDepositFor,
      (t, { payload: p }: { payload: Actions.SPDepositForType }) => {
        t.spFor = t.spFor || {};
        const key = `${p.chainId}`;
        t.spFor[key] = t.spFor[key] || {};
        t.spFor[key][p.who] = t.spFor[key][p.who] || {};
        t.spFor[key][p.who]['deposit'] = p.amount;
      },
    )
    .addCase(
      Actions.updateRewardsFor,
      (t, { payload: p }: { payload: Actions.SPRewardsForType }) => {
        t.spFor = t.spFor || {};
        const key = `${p.chainId}`;
        t.spFor[key] = t.spFor[key] || {};
        t.spFor[key][p.who] = t.spFor[key][p.who] || {};
        t.spFor[key][p.who]['rewards'] = t.spFor[key][p.who]['rewards'] || {};
        Object.entries(p.rewards).forEach((data, index) => {
          t.spFor[key][p.who]['rewards'][data[0]] = data[1];
        });
      },
    ),
);
