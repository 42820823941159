import {
  ContractCallContext,
  ContractCallResults,
  Multicall as MultiCall,
} from 'ethereum-multicall';
import { ethers } from 'ethers';

import { EventEmitter } from 'events';

export default class Multicall extends EventEmitter {
  cache: { [key: string]: string } = {};
  calls: ContractCallContext[] = [];
  multicall: MultiCall;

  chainId: number;
  intervalId: NodeJS.Timer;

  constructor(provider: ethers.providers.JsonRpcProvider, chainId: number) {
    super();
    this.calls = [];

    // if (chainId === 169) {
    this.multicall = new MultiCall({
      multicallCustomContractAddress:
        '0xcA11bde05977b3631167028862bE2a173976CA11',
      ethersProvider: provider,
      tryAggregate: true,
    });
    // } else {
    //   this.multicall = new MultiCall({
    //     ethersProvider: provider,
    //     tryAggregate: true,
    //   });
    // }

    this.chainId = chainId;
    this.onTick();
    this.intervalId = setInterval(this.onTick, 1000 * 5); // 5 second tick
  }

  addCall = (data: ContractCallContext) => {
    this.calls.push(data);
  };

  resetCalls = () => {
    this.calls = [];
  };

  tick = () => {
    this.onTick();
  };

  addCalls = (data: ContractCallContext[]) => {
    data.forEach((d) => this.calls.push(d));
  };

  private onTick = async () => {
    const ret: ContractCallResults = await this.multicall.call(this.calls);
    const keys = Object.keys(ret.results);

    keys.forEach((key) => {
      const val = ret.results[key];

      // todo: make this on changes only
      const cached = this.cache[key];
      const newCached = JSON.stringify(val.callsReturnContext);

      if (cached !== newCached) {
        this.emit(key, val.callsReturnContext);
        this.cache[key] = newCached;
      }
    });
  };
}
