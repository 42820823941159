import { ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface IProps {
  children: ReactNode;
}

const Container = (props: IProps & BoxProps) => {
  return (
    <Box maxWidth={580} ml={'auto'} mr={'auto'} {...props}>
      {props.children}
    </Box>
  );
};

export default Container;
