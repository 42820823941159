import { useSelector } from 'react-redux';
import { AppState } from '../index';
import useCore from '../../hooks/useCore';
import { BigNumber } from 'ethers';
import { Protocol } from '../../protocol/Protocol';

export function safeGetBalanceOf(
  balanceOf: {
    [token: string]: {
      [who: string]: BigNumber;
    };
  },
  chainId: number,
  token: string,
  who: string,
) {
  const bal = balanceOf[`${chainId}:${token}`];
  return bal && bal[who] ? bal[who] : BigNumber.from(0);
}

export function useTokenBalanceOf(chainId: number, token: string, who: string) {
  return useSelector((state: AppState) => {
    return safeGetBalanceOf(state.token.balanceOf, chainId, token, who);
  });
}

export function useTokenBalance(chainId: number, token: string) {
  const core = useCore();
  return useTokenBalanceOf(chainId, token, core.myAccount);
}

export function useTokenApprovalOf(
  chainId: number,
  token: string,
  from: string,
  to: string,
) {
  return useSelector((state: AppState) => {
    const bal = state.token.approvals[`${chainId}:${token}`];
    return bal && bal[from] && bal[from][to]
      ? bal[from][to]
      : BigNumber.from(0);
  });
}

//Token Price Feed
export function safeGetPriceFeedOf(
  priceFeed: {
    [token: string]: number;
  },
  token: string,
  chainId: number,
): number {
  return priceFeed[`${chainId}:${token}`] || 0;
}

export function useTokenPriceOfByAddress(
  chainId: number,
  tokenAddress: string,
) {
  return useSelector((state: AppState) => {
    return safeGetPriceFeedOf(state.token.priceFeed, tokenAddress, chainId);
  });
}

export function useTokenPriceOfBySymbol(
  chainId: number,
  symbol: string,
  core: Protocol,
) {
  const tokenAddress = core._tokens[chainId][symbol].address || '';
  return useSelector((state: AppState) => {
    return safeGetPriceFeedOf(state.token.priceFeed, tokenAddress, chainId);
  });
}
