import { Box, BoxProps, HStack, Text, TextProps } from '@chakra-ui/react';
import AnimatingNumber from '../modules/overview/AnimatingNumber';
import { ReactNode, useContext } from 'react';
import { ThemeContext } from '../navigation';

interface IProps {
  label: string;
  labelAction?: ReactNode;
  value?: string;
  valueInUsd?: string;
  symbol?: string;
  lProps?: TextProps;
  vprops?: TextProps;
  sProps?: TextProps;
  animate?: {
    from: number;
    to: number;
    hrs: number;
    decimals: number;
  };
}

const DataInfo = (props: BoxProps & IProps) => {
  return (
    <Box {...props}>
      <HStack alignItems={'center'} justifyContent={'space-between'}>
        {props.label && (
          <Box>
            <HStack alignItems={'baseline'}>
              <Text color={'gray.700'} {...props.lProps} fontSize={18}>
                {props.label}
              </Text>
            </HStack>
            {props.labelAction}
          </Box>
        )}
        <Box textAlign={'right'}>
          <HStack alignItems={'baseline'}>
            {props.value && (
              <Box
                fontSize={36}
                fontFamily={'numbers'}
                color={'gray.900'}
                {...props.vprops}
              >
                {props.animate ? (
                  <AnimatingNumber
                    from={props.animate.from}
                    to={props.animate.to}
                    duration={60 * props.animate.hrs}
                    decimals={props.animate.decimals}
                  />
                ) : (
                  props.value
                )}
              </Box>
            )}
            {props.symbol && (
              <Text fontSize={28} color={'gray.500'} {...props.sProps}>
                {props.symbol}
              </Text>
            )}
          </HStack>
          {props.valueInUsd && (
            <Text
              fontSize={14}
              color={'gray.500'}
              mt={-1}
              fontFamily={'numbers'}
            >
              {props.valueInUsd}
            </Text>
          )}
        </Box>
      </HStack>
    </Box>
  );
};

export default DataInfo;
