import { BigNumber } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';

export function getBalance(balance: BigNumber, decimals = 18): string {
  try {
    return formatUnits(balance, decimals);
  } catch (err) {
    return '0';
  }
}

export const getDisplayBalance = (
  balance: BigNumber,
  decimals = 18,
  fractionDigits = 3,
): string => {
  try {
    const formattedBalance: string = getBalance(balance, decimals);
    const decimalsPointIndex = formattedBalance.indexOf('.');
    if (decimalsPointIndex === -1) return formattedBalance;
    return (
      formattedBalance.slice(0, decimalsPointIndex) +
      '.' +
      formattedBalance.slice(
        decimalsPointIndex + 1,
        decimalsPointIndex + 1 + fractionDigits,
      )
    );
  } catch (error) {
    return '0';
  }
};

export const formatToBN = (
  value: number | string,
  decimals: number,
): BigNumber => {
  try {
    const [beforeDecimals, afterDecimal] = `${value}`.split('.');

    const beforeDecimalsPrecisionText = beforeDecimals?.slice(0, 18) || '0';
    const afterDecimalsPrecisionText = afterDecimal?.slice(0, decimals) || '0';
    const fixedPrecisionValue = `${beforeDecimalsPrecisionText}.${afterDecimalsPrecisionText}`;

    return BigNumber.from(parseUnits(`${fixedPrecisionValue}`, decimals));
  } catch (error) {
    return BigNumber.from('0');
  }
};

export const getTokenBNToUSD = (
  tokenVal: BigNumber,
  tokenUsdValue: number,
  tokenDecimals = 18,
): number => {
  return Number(getDisplayBalance(tokenVal, tokenDecimals, 10)) * tokenUsdValue;
};

export const getTokenToUSD = (
  tokenVal: number,
  tokenUsdValue: number,
): number => {
  return tokenVal * tokenUsdValue;
};

export function getCompactNumber(_n: number): string {
  let USformatter = new Intl.NumberFormat('en-US', { notation: 'compact' });
  return USformatter.format(_n);
}
