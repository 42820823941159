import { configureStore } from '@reduxjs/toolkit';
import { load, save } from 'redux-localstorage-simple';

import app from './app/reducer';
import transactions from './transactions/reducer';
import trove from './troves/reducer';
import stability from './stability/reducer';
import token from './token/reducer';

const PERSISTED_KEYS: string[] = [
  'app',
  'token',
  'transactions',
  'trove',
  'stability',
];

const store = configureStore({
  reducer: { app, token, transactions, trove, stability },
  middleware: (getDefaultMiddleware) => {
    const res = getDefaultMiddleware({
      serializableCheck: false,
    }).concat(save({ states: PERSISTED_KEYS }));

    // return res.concat(createLogger());
    return res;
  },

  preloadedState: load({ states: PERSISTED_KEYS }),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
