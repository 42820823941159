import { Box, HStack, Text } from '@chakra-ui/react';
import ThemeBoxWrapper from '../../components/ThemeBoxWrapper';
import { useContext, useMemo } from 'react';
import { ThemeContext } from '../../navigation';
import { useSPDepositFor, useSPRewardsFor } from '../../store/stability/hooks';
import { useGetAccount, useGetChainId } from '../../hooks/useWagmiHooks';
import { getDisplayBalance } from '../../utils/formatBalance';
import { BigNumber } from 'ethers';

const UserSPInfo = () => {
  const { themecolor } = useContext(ThemeContext);
  const chainId = useGetChainId();
  const account = useGetAccount();
  const spRewards = useSPRewardsFor(chainId, account || '');
  const spDeposit = useSPDepositFor(chainId, account || '');

  const totalRewards = useMemo(() => {
    if (spRewards['ZERO']) {
      const tokenUsdValue = 1;
      return (
        tokenUsdValue *
        Number(getDisplayBalance(BigNumber.from(spRewards['ZERO']), 18, 5))
      );
    } else {
      return 0;
    }
  }, [spRewards]);

  if (
    spDeposit.eq(0) &&
    spRewards['ZERO'] &&
    BigNumber.from(spRewards['ZERO']).eq(0)
  )
    return <div />;

  return (
    <ThemeBoxWrapper themecolor={themecolor} mt={4}>
      <HStack>
        <Box flex={0.5} color={themecolor}>
          <Text textAlign={'center'} fontSize={18} opacity={0.8}>
            Your Deposit
          </Text>
          <HStack justifyContent={'center'} alignItems={'baseline'}>
            <Text textAlign={'center'} fontSize={32} fontFamily={'numbers'}>
              {getDisplayBalance(spDeposit, 18, 2)}
            </Text>
            <Text fontSize={22}>ONEZ</Text>
          </HStack>
        </Box>
        <Box flex={0.5} color={themecolor}>
          <Text textAlign={'center'} fontSize={18} opacity={0.8}>
            Claimable Rewards
          </Text>
          <HStack justifyContent={'center'} alignItems={'baseline'}>
            <Text fontSize={22}>$</Text>
            <Text textAlign={'center'} fontSize={32} fontFamily={'numbers'}>
              {totalRewards.toFixed(2)}
            </Text>
          </HStack>
          <Text textAlign={'center'} fontSize={12} opacity={0.6}>
            Starting Soon
          </Text>
        </Box>
      </HStack>
    </ThemeBoxWrapper>
  );
};

export default UserSPInfo;
