import { useCallback } from 'react';
import { useGetChainId } from '../useWagmiHooks';

import useCore from '../useCore';
import { useGetSignedContract } from '../useGetContract';
import { TroveDetails } from '../../utils/inteface';
import { useToast } from '@chakra-ui/react';
import formatErrorMessage from '../../utils/formatErrorMessage';

const useCloseTrove = (trove: TroveDetails, deadline: number) => {
  const core = useCore();
  const addToast = useToast();
  const chainId = useGetChainId();
  const delegateBorrowerOperation = core.getDelegateContractOf(
    chainId,
    trove.trove,
  );

  const contract = useGetSignedContract(
    delegateBorrowerOperation.contract.address,
    delegateBorrowerOperation.abi,
  );

  return useCallback(
    async (
      hash: string,
      onInitiating: () => void,
      onSuccess: () => void,
      onFailure: (e: string) => void,
    ): Promise<void> => {
      try {
        onInitiating();
        addToast({
          title: 'Closing Position',
          status: 'warning',
        });
        const response = await contract.closeTrove(deadline, hash);
        const tx = await response.wait();

        if (tx?.status === 1) {
          addToast({
            title: 'Position Closed Successfully',
            status: 'success',
          });
          onSuccess();
        }
        if (tx?.status !== 1) {
          addToast({
            title: 'Error',
            description: `Error Occurred while closing your position`,
            status: 'error',
          });
          onFailure('');
        }
      } catch (e: any) {
        console.log('useCloseTrove error', e?.data?.message || e?.message);
        onFailure('');
        addToast({
          title: `Error Occurred`,
          description: formatErrorMessage(e?.data?.message || e?.message),
          status: 'error',
        });
      }
    },
    [addToast, contract, deadline],
  );
};

export default useCloseTrove;
