export default (message: string): string => {
  message = message.toLowerCase();

  if (message.includes('trove is active'))
    return 'You already have an active position';
  if (message.includes('trove does not exist or is closed'))
    return 'Loan not found';
  if (message.includes('Caller doesnt have enough ONEZ to make repayment'))
    return 'No enough ONEZ available to close the loan';
  if (
    message.includes(
      `requested redemption amount must be <= user's token balance`,
    )
  )
    return 'Requested amount is less than User balance.';
  if (message.includes('user denied transaction'))
    return `Transaction was cancelled`;
  if (message.includes('only one trove in the system'))
    return `There should be atleast one active loan.`;
  if (message.includes('paused')) return 'This action is paused.';
  if (message.includes('cannot redeem when tcr < mcr'))
    return 'Cannot redeem when Total Collateral Ratio < 110%.';
  if (message.includes('redemptions are not allowed during bootstrap phase'))
    return 'Cannot redeem in bootstrap phase.';
  if (message.includes('is inactive'))
    return 'This action has been deactivated.';
  if (message.includes('fee percentage must be between'))
    return 'Invalid fee percentage.';
  if (message.includes('cannot withdraw while there are troves with icr < mcr'))
    return 'Cannot withdraw when there are active loans with collateral ratio < 110%.';
  if (
    message.includes('tag must be a registered front end, or the zero address')
  )
    return 'Frontend must be registered.';
  if (message.includes('kickback rate must be in range [0,1]'))
    return 'Kickback must be between 0 and 100%.';
  if (message.includes('cannot withdraw and add coll'))
    return 'Cannot withdraw and add collateral at the same time.';
  if (message.includes('not permitted during recovery mode'))
    return 'This operation is not permitted in Recovery mode.';
  if (message.includes('that would result in icr < mcr is not permitted'))
    return 'Operations that results in Collateral Ratio < 100% are not permitted.';
  if (message.includes('must leave trove with icr >= ccr'))
    return 'Operations must result in Collateral Ratio > Critical collateral ratio';
  if (message.includes(`cannot decrease your trove's icr in recovery mode`))
    return 'This operation is not permitted in Recovery mode.';
  if (message.includes(`that would result in tcr < ccr is not permitted`))
    return 'Operations that results in Total collateral ratio < Critical collateral ratio are not permitted.';
  if (message.includes('nothing to liquidate')) return 'Not under liquidation.';
  if (message.includes('calldata address array must not be empty'))
    return 'Cannot pass empty data.';
  if (message.includes('unable to redeem any amount'))
    return 'Unable to redeem any amount.';
  if (message.includes('fee would eat up all returned collateral'))
    return 'Fee would eat up all returned collateral.';
  if (
    message.includes(
      `requested redemption amount must be <= user's lusd token balance`,
    )
  )
    return `Requested redemption amount must be <= user's ONEZ token balance.`;
  if (message.includes(`amount must be greater than zero`))
    return `Amount must be greater than zero.`;
  if (message.includes(`max fee percentage must be between 0.5% and 100`))
    return `Max fee percentage must be between 0.5% and 100.`;
  if (message.includes(`user must have a non-zero deposit`))
    return `User must have a non-zero deposit.`;
  if (message.includes(`user must have no deposit`))
    return `User must have no deposit.`;
  if (
    message.includes(`caller must have an active trove to withdraw ethgain to`)
  )
    return `Must have an active loan to withdraw gain to loan.`;
  if (message.includes(`caller must have non-zero eth gain`))
    return `Must have non-zero Gain.`;
  if (
    message.includes(
      `there must be either a collateral change or a debt change`,
    )
  )
    return `There must be either a collateral change or a debt change.`;
  if (message.includes(`collateral withdrawal not permitted recovery mode`))
    return `Collateral withdrawal not permitted Recovery Mode.`;
  if (message.includes(`trove's net debt must be greater than minimum`))
    return `Loan's net debt must be greater than minimum.`;
  if (message.includes(`operation must leave trove with icr >= ccr`))
    return `Operation must leave loan with ICR >= CCR.`;
  if (message.includes(`fee exceeded provided maximum`))
    return `Fee exceeded provided maximum.`;

  // Fail safes like overflows etc.;
  return 'Please try again later';
};
