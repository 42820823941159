import { Box, BoxProps, HStack } from '@chakra-ui/react';
import { ReactNode, useContext } from 'react';
import { IColorsType } from '../../utils/inteface';
import { ThemeContext } from '../../navigation';

interface IProps extends BoxProps {
  label?: ReactNode;
  data?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  theme?: IColorsType;
}

const InputContainer = (props: IProps) => {
  const { themecolor } = useContext(ThemeContext);

  return (
    <>
      <Box bgColor={`${themecolor}.50`} p={4} borderRadius={8}>
        {(props.label || props.data) && (
          <HStack
            alignItems={'baseline'}
            justifyContent={'space-between'}
            mb={2}
          >
            <Box color={`${themecolor}.500`} fontSize={{ base: 12, md: 14 }}>
              {props.label ? props.label : ''}
            </Box>
            <Box
              color={`${themecolor}.500`}
              textAlign={'right'}
              fontSize={{ base: 14, md: 16 }}
            >
              {props.data ? props.data : ''}
            </Box>
          </HStack>
        )}
        {props.children}
      </Box>
      {props.footer && (
        <Box color={'gray.500'} fontSize={12} mt={1} px={4}>
          {props.footer}
        </Box>
      )}
    </>
  );
};

export default InputContainer;
