import { Box, BoxProps, theme } from '@chakra-ui/react';
import { IColorsType } from '../utils/inteface';

interface IProps {
  themecolor: IColorsType;
}

const ThemeBoxWrapper = (props: BoxProps & IProps) => {
  const { themecolor = 'purple' } = props;
  return (
    <Box
      _before={{
        content: '""',
        display: 'flex',
        position: 'absolute',
        inset: '0',
        transform: 'scale(1.01)',
        // filter: "blur(50px)",
        // backgroundColor: `${theme.colors[themecolor]["100"]}`,
        zIndex: -2,
      }}
      boxShadow={`0 5px 15px 0px ${theme.colors[themecolor]['100']}`}
      animation={'floatShadowAnimation 6s linear infinite'}
      position={'relative'}
      border={`1px solid ${theme.colors[themecolor]['500']}`}
      borderRadius={'lg'}
      bg={'white'}
      overflow={'hidden'}
      p={4}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default ThemeBoxWrapper;
